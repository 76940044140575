// --------------------------------------------------------------
// Created On: 2025-02-04
// Author: Hannah Vaughan
//
// Last Modified: 2025-02-28
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useMemo, useState } from "react";
import Spinner from "src/components/Spinner/Spinner";
import useApi from "src/hooks/useApi";
import {
  API,
  CUSTOMER_SERVICE_EMAIL,
  CUSTOMER_SERVICE_OVERRIDE_MESSAGE,
  CUSTOMER_SERVICE_PHONE,
  COPILOT_EDGE_DEVICE_TYPE,
} from "src/constants/miscellaneous";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextBlurb from "src/components/TextBlurb/TextBlurb";
import getApiError from "src/utilities/api/getApiError";
import { getCurrentUser } from "../../redux/selectors";
import { useSelector } from "react-redux";
import Card from "src/components/Card/Card";

// Page for claiming devices by the use of a QR code. Only some devices support this feature, such as the Co-Pilot Edge.
export default function ClaimDevicePage(): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyAlreadyClaimed, setCompanyAlreadyClaimed] = useState<boolean>(false);
  const [deviceClaimed, setDeviceClaimed] = useState<boolean>(false);
  const [deviceIdentifierInput, setDeviceIdentifierInput] = useState<string>("");
  const [claimCodeInput, setClaimCodeInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { deviceIdentifier, claimCode } = useParams();
  const cardTitle = useMemo(() => getCardTitle(), [deviceClaimed, companyAlreadyClaimed]);
  const textBlurbTitle = useMemo(() => getTextBlurbTitle(), [deviceClaimed, companyAlreadyClaimed]);
  const textBlurbParagraph = useMemo(() => getParagraph(), [deviceClaimed, companyAlreadyClaimed]);
  const textBlurbIcon = useMemo(() => getIcon(), [deviceClaimed, companyAlreadyClaimed]);
  const currentUser = useSelector(getCurrentUser);
  const navigate = useNavigate();

  // Gets the customer service message to send to the user in the event an error occurs.
  function getCustomerServiceMessage(): string {
    if (CUSTOMER_SERVICE_OVERRIDE_MESSAGE === null) {
      return (
        `If you believe this is an error, please contact customer service at ${CUSTOMER_SERVICE_EMAIL} ` +
        `or by phone at ${CUSTOMER_SERVICE_PHONE}.`
      );
    } else {
      return `If you believe this is an error, ${CUSTOMER_SERVICE_OVERRIDE_MESSAGE}`;
    }
  }

  // Gets the title of the page's Card component based on the API results.
  function getCardTitle(): string {
    if (errorMessage.length > 0) {
      return "Error Claiming Device";
    } else if (deviceClaimed) {
      return "Device Claimed Successfully";
    } else if (companyAlreadyClaimed) {
      return "Device Already Claimed";
    } else if (!deviceClaimed && !companyAlreadyClaimed) {
      return "Device Not Claimed";
    } else {
      return "";
    }
  }

  // Gets the title of the TextBlurb component based on the API results.
  function getTextBlurbTitle(): string {
    if (errorMessage.length > 0) {
      return "Error Occurred While Claiming Device";
    } else if (deviceClaimed) {
      return `Device ${deviceIdentifier} Has Now Been Claimed`;
    } else if (companyAlreadyClaimed) {
      return `Device ${deviceIdentifier} Already Claimed by Current Company`;
    } else if (!deviceClaimed && !companyAlreadyClaimed) {
      return `Device ${deviceIdentifier} Could Not Be Claimed`;
    } else {
      return "";
    }
  }

  // Gets the paragraph of the TextBlurb component based on the API results.
  function getParagraph(): string {
    if (errorMessage.length > 0) {
      return `The device with identifier ${deviceIdentifier} could not be claimed due to the following error: ${errorMessage}`;
    } else if (deviceClaimed) {
      return `The device with identifier ${deviceIdentifier} was successfully claimed with claim code ${claimCode}.`;
    } else if (companyAlreadyClaimed) {
      return `The device with identifier ${deviceIdentifier} could not be claimed because it was already claimed by the current company.`;
    } else if (!deviceClaimed && !companyAlreadyClaimed) {
      return `The device with identifier ${deviceIdentifier} could not be claimed because it already belongs to another company.\n${getCustomerServiceMessage()}`;
    } else {
      return "";
    }
  }

  // Gets the icon for the TextBlurb component based on if the device gets claimed or not.
  function getIcon(): string {
    if (deviceClaimed && errorMessage.length === 0) {
      return "check-circle";
    } else {
      return "exclamation-triangle";
    }
  }

  // Claim the device and evaluate whether it was claimed or not.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      url: `${API}/company/${currentUser.companyId}/device/claim`,
      method: "POST",
      body: { deviceType: COPILOT_EDGE_DEVICE_TYPE, deviceIdentifier: deviceIdentifier, claimCode: claimCode },
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setCompanyAlreadyClaimed(responseBody.companyAlreadyClaimed);
        setDeviceClaimed(responseBody.deviceClaimed);
        setErrorMessage("");
      } else {
        setErrorMessage(await getApiError(response, "Unable to claim device."));
      }
      setDeviceIdentifierInput("");
      setClaimCodeInput("");
      setLoading(false);
    },
    [deviceIdentifier, claimCode]
  );

  return (
    <div className="p-4 mx-auto">
      <Spinner loading={loading} />

      {(deviceIdentifier === undefined || claimCode === undefined) && (
        <Card title="Claim Device">
          <div className="my-3 row d-flex justify-content-center">
            <div className="col-6">Please enter the device identifier and claim code to claim the device.</div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-6 mb-3">
              <label htmlFor="deviceIdentifierInput" className="form-label">
                Device Identifier
              </label>
              <input
                type="text"
                className="form-control"
                id="deviceIdentifierInput"
                onChange={(e) => setDeviceIdentifierInput(e.target.value)}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-6 mb-3">
              <label htmlFor="claimCodeInput" className="form-label">
                Claim Code
              </label>
              <input
                type="text"
                className="form-control"
                id="claimCodeInput"
                onChange={(e) => setClaimCodeInput(e.target.value)}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-6 mb-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => navigate(`/claim-device/${deviceIdentifierInput}/${claimCodeInput}`)}
              >
                Claim Device
              </button>
            </div>
          </div>
        </Card>
      )}

      {cardTitle.length > 0 && deviceIdentifier !== undefined && claimCode !== undefined && (
        <Card title={cardTitle}>
          <div className="mx-auto my-5">
            <TextBlurb title={textBlurbTitle} paragraph={textBlurbParagraph} icon={textBlurbIcon} />
            <div className="d-flex justify-content-center mt-4">
              <Link className="btn btn-primary" to="/dashboard">
                Return to Dashboard
              </Link>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}

interface ResponseBody {
  companyAlreadyClaimed: boolean;
  deviceClaimed: boolean;
}
