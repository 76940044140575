// --------------------------------------------------------------
// Created On: 2023-02-06
// Author: Zachary Thomas
//
// Last Modified: 2025-02-18
// Modified By: Dimitra Weinstein
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import useApi from "../../../../hooks/useApi";
import { API, GEOFENCE_TYPE_STATIC, GEOFENCE_TYPE_DYNAMIC } from "../../../../constants/miscellaneous";
import deepCopy from "../../../../utilities/deepCopy";
import GeofenceMap from "./GeofenceMap/GeofenceMap";
import GeofenceItem from "./GeofenceItem/GeofenceItem";
import Spinner from "../../../../components/Spinner/Spinner";
import TabList from "../../../../components/TabList/TabList";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../../redux/selectors";
import styles from "./GeofenceTab.module.scss";

// Tab for drawing geo-fences for an asset group.
export default function GeofenceTab(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [assetPoints, setAssetPoints] = useState<GeofenceAsset[]>([]);
  const [showMapAssets, setShowMapAssets] = useState<string>("None");
  const currentUser = useSelector(getCurrentUser);

  // Get the location data for all company assets if the correct option is selected.
  useApi(
    () => {
      if (showMapAssets === "All") {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/location`,
    },
    async (response: Response, responseBody: GetAssetResponseBody) => {
      if (response.ok && responseBody) {
        props.onError("");
        setAssetPoints(responseBody.assetPoints);
      } else {
        props.onError("Internal server error. Unable to get asset information.");
        setAssetPoints([]);
      }
      setLoading(false);
    },
    [showMapAssets]
  );

  // Get the location data for associated assets if the correct option is selected.
  useApi(
    () => {
      if (showMapAssets === "Associated") {
        setLoading(true);
        return true;
      } else {
        return false;
      }
    },
    {
      method: "POST",
      url: `${API}/company/${currentUser.companyId}/asset/location`,
      body: {
        assetIds: props.assets.map((asset) => asset.assetId),
        assetgroupIds: props.assetgroups.map((assetgroup) => assetgroup.assetgroupId),
      },
    },
    async (response: Response, responseBody: GetAssetResponseBody) => {
      if (response.ok && responseBody) {
        props.onError("");
        setAssetPoints(responseBody.assetPoints);
      } else {
        props.onError("Internal server error. Unable to get asset information.");
        setAssetPoints([]);
      }
      setLoading(false);
    },
    [showMapAssets, JSON.stringify(props.assets), JSON.stringify(props.assetgroups)]
  );

  // Update geofence by modifying, adding, or deleting a geofence point.
  function modifyGeofence(geofencePointId?: number, latitude?: number, longitude?: number): void {
    const pointsDeepCopy = deepCopy(props.points);
    // We can check if this is an update or an addition based on if an ID exists.
    if (geofencePointId === undefined && longitude !== undefined && latitude !== undefined) {
      // We are creating a new point.
      // Get the next logical ID.
      let geofencePointId = 1;
      if (pointsDeepCopy.length > 0) {
        const lastPoint = pointsDeepCopy[pointsDeepCopy.length - 1];
        if (lastPoint.geofencePointId !== undefined) {
          geofencePointId = lastPoint.geofencePointId + 1;
        }
      }
      // Add the new geofence point.
      pointsDeepCopy.push({
        latitude: latitude,
        longitude: longitude,
        geofencePointId: geofencePointId,
      });
    } else if (geofencePointId !== undefined && longitude !== undefined && latitude !== undefined) {
      // We are modifying an existing point.
      const pointIndex = pointsDeepCopy.findIndex((point) => point.geofencePointId === geofencePointId);
      if (pointIndex >= 0) {
        pointsDeepCopy[pointIndex] = {
          latitude: latitude,
          longitude: longitude,
          geofencePointId: geofencePointId,
        };
      }
    } else if (geofencePointId !== undefined && longitude === undefined && latitude === undefined) {
      // We are deleting an existing point.
      const pointIndex = pointsDeepCopy.findIndex((point) => point.geofencePointId === geofencePointId);
      if (pointIndex >= 0) {
        pointsDeepCopy.splice(pointIndex, 1);
      }
    }
    props.onChangeGeofence(props.enabled, props.type, pointsDeepCopy);
  }

  return (
    <div>
      <Spinner loading={loading} />

      {/* Interactive geofence map. */}
      <GeofenceMap
        assetPoints={assetPoints}
        showMapAssets={showMapAssets !== "None"}
        geofencePoints={props.points}
        previousGeofencePoints={props.previousPoints}
        geofenceEnabled={props.enabled}
        disabled={props.disabled}
        onUpdatePoint={(geofencePointId, latitude, longitude) => modifyGeofence(geofencePointId, latitude, longitude)}
        onCreatePoint={(latitude, longitude) => modifyGeofence(undefined, latitude, longitude)}
      />

      {/* Association menu tabs.*/}
      <div className={`${styles.body} my-4`}>
        <div className="row mx-2">
          <TabList
            tabs={props.tabs}
            selectedTabId={props.tabId}
            onSelect={(tabId: string) => props.onClickTab(tabId)}
          />

          <div className={`${styles.tabsBar} py-3 text-center`}>
            {/* Show assets switch. */}
            <label className={styles.switchLabel}>Showing Assets:</label>

            <div className="row justify-content-center my-2">
              <div className="col">
                <input
                  className="form-check-input me-2"
                  type="radio"
                  name="assetViewMode"
                  value="None"
                  checked={showMapAssets === "None"}
                  onChange={(e) => setShowMapAssets(e.target.value)}
                />
                <label className={styles.modeLabel}>None</label>
              </div>

              <div className="col">
                <input
                  className="form-check-input me-2"
                  type="radio"
                  name="assetViewMode"
                  value="Associated"
                  checked={showMapAssets === "Associated"}
                  onChange={(e) => setShowMapAssets(e.target.value)}
                />
                <label className={styles.modeLabel}>Associated</label>
              </div>

              <div className="col">
                <input
                  className="form-check-input me-2"
                  type="radio"
                  name="assetViewMode"
                  value="All"
                  checked={showMapAssets === "All"}
                  onChange={(e) => setShowMapAssets(e.target.value)}
                />
                <label className={styles.modeLabel}>All</label>
              </div>
            </div>

            <div className="table-responsive">
              <table className={`${styles.geofenceTable} table w-auto mx-auto my-3`}>
                <tbody>
                  {/* Enable geofencing switch. */}
                  <tr>
                    <td className="align-middle">
                      <div className="form-check form-switch">
                        <input
                          className={`${styles.switch} form-check-input me-2`}
                          type="checkbox"
                          checked={props.enabled}
                          disabled={props.disabled}
                          onChange={() => props.onChangeGeofence(!props.enabled, props.type, props.points)}
                        />
                      </div>
                    </td>

                    <td className="align-start">
                      {props.enabled ? (
                        <label
                          className={styles.switchLabel}
                          onClick={() => props.onChangeGeofence(!props.enabled, props.type, props.points)}
                        >
                          Geo-fence is enabled
                        </label>
                      ) : (
                        <label
                          className={styles.switchLabel}
                          onClick={() => props.onChangeGeofence(!props.enabled, props.type, props.points)}
                        >
                          Geo-fence is disabled
                        </label>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Select geofence rules. */}
              <label className={styles.typeLabel}>Geo-fence rules</label>
              <select
                className={`${styles.geofenceType} form-select`}
                value={props.type}
                disabled={props.disabled}
                onChange={(e) => props.onChangeGeofence(props.enabled, e.target.value, props.points)}
              >
                <option value={GEOFENCE_TYPE_STATIC}>Basic Geo-fence: Visible on map and supports alerting.</option>
                <option value={GEOFENCE_TYPE_DYNAMIC}>
                  Dynamic Geo-fence: Associate / disassociate assets when they enter / exit.
                </option>
              </select>
            </div>
          </div>

          {/* List of geofence points. */}
          <div className={`${styles.tabsContainer} p-0`}>
            {props.points
              .slice(0)
              .reverse()
              .map((geofencePoint) => (
                <Fragment key={geofencePoint.geofencePointId}>
                  {geofencePoint.geofencePointId !== undefined && (
                    <GeofenceItem
                      geofencePointId={geofencePoint.geofencePointId}
                      latitude={geofencePoint.latitude}
                      longitude={geofencePoint.longitude}
                      disabled={props.disabled}
                      onUpdatePoint={(geofencePointId, latitude, longitude) =>
                        modifyGeofence(geofencePointId, latitude, longitude)
                      }
                      onDeletePoint={(geofencePointId) => modifyGeofence(geofencePointId)}
                    />
                  )}
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

GeofenceTab.propTypes = {
  tabId: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  assetgroupId: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  points: PropTypes.array.isRequired,
  previousPoints: PropTypes.array.isRequired,
  assets: PropTypes.array.isRequired,
  assetgroups: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClickTab: PropTypes.func.isRequired,
  onChangeGeofence: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

interface Props {
  tabId: string;
  tabs: ManagementTab[];
  assetgroupId: number;
  enabled: boolean;
  type: string;
  points: GeofencePoint[];
  previousPoints: GeofencePoint[];
  assets: AssetSelectionOption[];
  assetgroups: AssetgroupSelectionOption[];
  disabled: boolean;
  onClickTab: (tabId: string) => void;
  onChangeGeofence: (enabled: boolean, type: string, points: GeofencePoint[]) => void;
  onError: (errorMessage: string) => void;
}

interface GeofencePoint {
  geofencePointId: number;
  latitude: number;
  longitude: number;
  draggable?: boolean;
}

interface GetAssetResponseBody {
  assetPoints: GeofenceAsset[];
}

interface GeofenceAsset {
  assetId: number;
  assetMapIconCode: string;
  name: string;
  latitude: string;
  longitude: string;
}

interface AssetgroupSelectionOption {
  assetgroupId: number;
  name: string;
  isDefault: boolean;
}

interface AssetSelectionOption {
  assetId: number;
  name: string;
  nickname: string;
  isRented?: boolean;
  isMigrating?: boolean;
  lastConfigSuccessful?: boolean;
  productManufacturer?: string;
  productModel?: string;
  productType?: string;
}

interface ManagementTab {
  tabId: string;
  name: string;
  shortName: string;
  breakPoints: string;
}
