// --------------------------------------------------------------
// Created On: 2021-03-09
// Author: Zachary Thomas
//
// Last Modified: 2025-03-15
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

/// <reference types="google.maps" />
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GoogleMap, useJsApiLoader, Polygon, Marker, InfoWindow } from "@react-google-maps/api";
import MapAssetAlertBanner from "./MapAssetAlertBanner/MapAssetAlertBanner";
import deepCopy from "../../../utilities/deepCopy";
import calculateCenterZoom from "../../../utilities/calculateCenterZoom";
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_DEFAULT_ZOOM,
  GOOGLE_MAPS_DEFAULT_LAT,
  GOOGLE_MAPS_DEFAULT_LNG,
  GOOGLE_MAPS_BOTTOM_LEFT,
  GOOGLE_MAPS_HORIZONTAL_BAR,
  GOOGLE_MAPS_ROAD_MAP,
  GOOGLE_MAPS_SATELLITE,
  GOOGLE_MAPS_MIN_ZOOM,
  GOOGLE_MAPS_DELAY_TO_CLEANUP_BROKEN_INFO_WINDOWS_MILLISECONDS,
} from "../../../constants/googleMaps";
import isoUtcIsStale from "../../../utilities/time/isoUtcIsStale";
import isoToTimePassed from "../../../utilities/time/isoToTimePassed";
import styles from "./GroupMap.module.scss";

// Map that displays asset locations from an asset group.
export default function GroupMap(props: Props): Component {
  const [assets, setAssets] = useState<VerifiedMapAsset[]>([]);
  const [center, setCenter] = useState<ShortPoint>({
    lat: GOOGLE_MAPS_DEFAULT_LAT,
    lng: GOOGLE_MAPS_DEFAULT_LNG,
  });
  const [zoom, setZoom] = useState<number>(GOOGLE_MAPS_DEFAULT_ZOOM);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [setupSelectedAsset, setSetupSelectedAsset] = useState<VerifiedMapAsset | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<VerifiedMapAsset | null>(null);
  const [markerMap, setMarkerMap] = useState<MarkerMap>({});
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  // Draw geofence polygons to the map.
  useEffect(() => {
    let newGeofencePolygon: google.maps.Polygon | null = null;
    if (map && props.geofencePoints && props.geofenceEnabled) {
      // Get the complete geofence path.
      const geofencePath = props.geofencePoints.map((geofencePoint) => {
        return { lat: geofencePoint.latitude, lng: geofencePoint.longitude };
      });

      // Draw the new geofence polygon.
      if (geofencePath.length > 0) {
        newGeofencePolygon = new google.maps.Polygon({
          paths: geofencePath,
          strokeColor: "#3957d9",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#3957d9",
          fillOpacity: 0.35,
        });

        if (newGeofencePolygon !== null) {
          newGeofencePolygon.setMap(map);
        }
      }
    }

    // Clean up old polygons off the map.
    return () => {
      if (newGeofencePolygon !== null) {
        newGeofencePolygon.setMap(null);
      }
    };
  }, [map, props.geofenceEnabled, JSON.stringify(props.geofencePoints)]);

  // Get the required center and zoom level to fit all markers on the map.
  useEffect(() => {
    // Only include assets with valid location data.
    const tempAssets: VerifiedMapAsset[] = [];
    const tempPoints: Point[] = [];
    props.assets.forEach((asset) => {
      if (asset.latitude !== null && asset.longitude !== null) {
        tempAssets.push(deepCopy(asset as VerifiedMapAsset));
        tempPoints.push({
          latitude: asset.latitude,
          longitude: asset.longitude,
        });
      }
    });

    if (!props.preventMapReposition) {
      // Find the new bounding box that fits all markers.
      if (map !== null && tempPoints.length > 0) {
        const newBounds = calculateCenterZoom(tempPoints, map);
        setCenter(newBounds.center);
        setZoom(newBounds.zoom - 1);
      } else {
        setZoom(GOOGLE_MAPS_DEFAULT_ZOOM);
      }
    }

    setAssets(tempAssets);
    setSetupSelectedAsset(null);
    setSelectedAsset(null);
  }, [JSON.stringify(props.assets), map, props.preventMapReposition]);

  // Update the selected asset. We have to handle it this way due to a bug with InfoWindow not updating anchors unless
  // the anchor was first reset to null.
  useEffect(() => {
    if (setupSelectedAsset !== null) {
      setSelectedAsset(setupSelectedAsset);
      setSetupSelectedAsset(null);
      // This is used to account for a bug in the 'react-google-maps/api' library where the info window does not display
      // properly when a marker is clicked.
      setTimeout(() => {
        const infoWindowElements = document.querySelectorAll(".gm-style-iw, .gm-style-iw-c");
        infoWindowElements.forEach((element) => {
          // If the element is an empty info window we remove it.
          if (elementIsEmptyInfoWindow(element)) {
            (element.parentElement as HTMLElement).remove();
          }
        });
      }, GOOGLE_MAPS_DELAY_TO_CLEANUP_BROKEN_INFO_WINDOWS_MILLISECONDS);
    }
  }, [setupSelectedAsset]);

  // We remove the info window if it is just an empty window with an X to close. We can identify this element
  // based on the number of children (2) and the lack of grandchildren.
  // This is the pattern we expect to see generated with the bug in 'react-google-maps/api'.
  function elementIsEmptyInfoWindow(element: Element): boolean {
    const children = Array.from(element.children);
    return (
      element.parentElement !== null &&
      children.length === 2 &&
      (children[0].children.length === 0 || children[1].children.length === 0)
    );
  }

  // Handle load map event.
  function handleLoadMap(map: google.maps.Map): void {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }

  // Handle marker click.
  function handleMarkerClick(asset: VerifiedMapAsset): void {
    // Set the selected asset to null before setting it to a specific value using this function and a use effect.
    // This prevents an issue with the InfoWindow where the anchor is not updated correctly.
    setSetupSelectedAsset(asset);
    setSelectedAsset(null);
  }

  // Get attribute array from attribute mapping object.
  function getAttributeArrayFromAttributeMap(valueByAttributeId: ValueByAttributeId): HighlightableAttribute[] {
    const attributes: HighlightableAttribute[] = [];
    for (const key in valueByAttributeId) {
      const attributeId = parseInt(key, 10);
      if (!isNaN(attributeId)) {
        attributes.push({
          attributeId: attributeId,
          value: valueByAttributeId[key].value,
          triggeredHighlightColor: valueByAttributeId[key].triggeredHighlightColor,
        });
      }
    }
    return attributes;
  }

  // Get marker icon info based on highlighting rules and if the asset has stale data.
  function getAssetMarkerIcon(asset: VerifiedMapAsset): google.maps.Icon {
    const iconUrl = `/mapMarkers/mapMarker${asset.assetMapIconCode}${
      isoUtcIsStale(asset.lastComm) ? "Stale" : "Active"
    }${asset.highlightColorCode === null ? "" : `_${asset.highlightColorCode.slice(1)}`}.png`;

    return {
      url: iconUrl,
      size: new google.maps.Size(40, 48),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 48),
      scaledSize: new google.maps.Size(40, 48),
    };
  }

  // Handle load event of marker so that it aligns correctly with info windows.
  function handleMarkerLoad(marker: google.maps.Marker, asset: VerifiedMapAsset): void {
    setMarkerMap((prevState) => {
      return { ...prevState, [asset.assetId]: marker };
    });
  }

  // Handel unmount map event.
  function handleUnmountMap(): void {
    setMap(null);
  }

  // Get attribute highlight styling details for a single attribute of an asset.
  function getAttributeHighlightStyling(
    asset: VerifiedMapAsset | null,
    attribute: MapAttribute,
    index: number
  ): AttributeHighlightStyle {
    let backgroundColor = "initial";
    if (asset !== null && asset.highlightColorCode !== null && attribute.triggeredHighlightColor) {
      backgroundColor = asset.highlightColorCode;
    }

    let borderRadius = "0";
    if (asset !== null) {
      const attributes = getAttributeArrayFromAttributeMap(asset.valueByAttributeId);
      // Get border radius for top half of highlights.
      if (attributes.length > index - 1 && index > 0 && attributes[index - 1].triggeredHighlightColor) {
        borderRadius = "0 0 ";
      } else {
        borderRadius = "5px 5px ";
      }

      // Get border radius for bottom half of highlights.
      if (attributes.length > index + 1 && attributes[index + 1].triggeredHighlightColor) {
        borderRadius += "0 0";
      } else {
        borderRadius += "5px 5px";
      }
    }

    return {
      backgroundColor: backgroundColor,
      borderRadius: borderRadius,
      padding: "0 0.3rem",
    };
  }

  return (
    <div className={styles.body} id="google-maps-container">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "85vh" }}
          center={center}
          zoom={zoom}
          onLoad={(map) => handleLoadMap(map)}
          onUnmount={() => handleUnmountMap()}
          options={{
            fullscreenControl: false,
            scaleControl: true,
            mapTypeControl: true,
            tilt: 0,
            minZoom: GOOGLE_MAPS_MIN_ZOOM,
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
            mapTypeControlOptions: {
              position: GOOGLE_MAPS_BOTTOM_LEFT,
              style: GOOGLE_MAPS_HORIZONTAL_BAR,
              mapTypeIds: [GOOGLE_MAPS_ROAD_MAP, GOOGLE_MAPS_SATELLITE],
            },
            restriction: {
              latLngBounds: {
                east: 180,
                north: 85,
                south: -85,
                west: -180,
              },
              strictBounds: true,
            },
          }}
        >
          {props.geofenceEnabled && props.geofencePoints.length > 0 && (
            <Polygon
              options={{
                paths: props.geofencePoints.map((geofencePoint) => {
                  return { lat: geofencePoint.latitude, lng: geofencePoint.longitude };
                }),
                strokeColor: "#3957d9",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#3957d9",
                fillOpacity: 0.35,
              }}
            />
          )}
          {assets.map((asset) => (
            <Marker
              key={asset.assetId}
              position={{ lat: asset.latitude, lng: asset.longitude }}
              title={asset.name}
              onLoad={(marker) => handleMarkerLoad(marker, asset)}
              onClick={() => handleMarkerClick(asset)}
              icon={getAssetMarkerIcon(asset)}
            />
          ))}
          {selectedAsset !== null && (
            <InfoWindow
              anchor={markerMap[selectedAsset.assetId]}
              onCloseClick={() => {
                setSelectedAsset(null);
                setSetupSelectedAsset(null);
              }}
            >
              <div className={styles.infoBody}>
                <div className={styles.infoHeader}>{selectedAsset.name}</div>
                {selectedAsset.nickname !== "" && <div className={styles.infoNickname}>{selectedAsset.nickname}</div>}
                <div className="mb-2">
                  ({selectedAsset.latitude}, {selectedAsset.longitude})
                  <div className={isoUtcIsStale(selectedAsset.lastComm) ? styles.staleHighlight : ""}>
                    Last reported {isoToTimePassed(selectedAsset.lastComm)} ago
                  </div>
                </div>
                {getAttributeArrayFromAttributeMap(selectedAsset.valueByAttributeId).map(
                  (attribute, i) =>
                    props.attributeDetailsByAttributeId[attribute.attributeId] !== null &&
                    props.attributeDetailsByAttributeId[attribute.attributeId] !== undefined && (
                      <div
                        key={attribute.attributeId}
                        style={getAttributeHighlightStyling(selectedAsset, attribute, i)}
                      >
                        {props.attributeDetailsByAttributeId[attribute.attributeId].name}:{" "}
                        {attribute.value === null ? "N/A" : attribute.value}
                        {props.attributeDetailsByAttributeId[attribute.attributeId].units === null
                          ? ""
                          : props.attributeDetailsByAttributeId[attribute.attributeId].units}
                      </div>
                    )
                )}
                <div className="my-2">
                  <a href={`/asset/${selectedAsset.assetId}/data`}>View Asset Details</a>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
      {!props.loading && <MapAssetAlertBanner assets={props.assets} errorMessage={props.errorMessage} />}
    </div>
  );
}

GroupMap.propTypes = {
  assets: PropTypes.array.isRequired,
  geofence: PropTypes.object,
  errorMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  preventMapReposition: PropTypes.bool.isRequired,
  attributeDetailsByAttributeId: PropTypes.object,
};

interface Props {
  assets: MapAsset[];
  geofenceEnabled: boolean;
  geofencePoints: GeofencePoint[];
  errorMessage: string;
  loading: boolean;
  preventMapReposition: boolean;
  attributeDetailsByAttributeId: AttributeDetailsByAttributeId;
}

interface AttributeDetailsByAttributeId {
  [key: string]: AttributeDetails;
}

interface AttributeDetails {
  name: string;
  units: string | null;
}

interface GeofencePoint {
  geofencePointId: number;
  latitude: number;
  longitude: number;
  draggable?: boolean;
}

interface Point {
  latitude: number;
  longitude: number;
}

interface ShortPoint {
  lat: number;
  lng: number;
}

interface MapAsset {
  assetId: number;
  assetMapIconCode: string;
  name: string;
  nickname: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  latitude: number | null;
  longitude: number | null;
  lastComm: string | null;
  highlightColorCode: string | null;
  valueByAttributeId: ValueByAttributeId;
}

interface VerifiedMapAsset {
  assetId: number;
  assetMapIconCode: string;
  name: string;
  nickname: string;
  deviceType: string | null;
  deviceIdentifier: string | null;
  latitude: number;
  longitude: number;
  lastComm: string | null;
  highlightColorCode: string | null;
  valueByAttributeId: ValueByAttributeId;
}

interface ValueByAttributeId {
  [key: string]: MapAttribute;
}

interface MapAttribute {
  value: string;
  triggeredHighlightColor: boolean;
}

interface HighlightableAttribute {
  attributeId: number;
  value: string;
  triggeredHighlightColor: boolean;
}

interface MarkerMap {
  [key: string]: google.maps.Marker;
}

interface AttributeHighlightStyle {
  backgroundColor: string;
  borderRadius: string;
  padding: string;
}
