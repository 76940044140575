// --------------------------------------------------------------
// Created On: 2023-09-23
// Author: Zachary Thomas
//
// Last Modified: 2025-02-18
// Modified By: Hannah Vaughan
//
// Copyright 2024 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { Fragment, useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Success from "../../../components/Success/Success";
import Warning from "../../../components/Warning/Warning";
import InviteResults from "./InviteResults/InviteResults";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import { API, VALID_EMAIL_REGULAR_EXPRESSION } from "../../../constants/miscellaneous";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import { USER_TYPES } from "../../../constants/reducerActions";
import styles from "./InviteUsersModal.module.scss";
import deepCopy from "src/utilities/deepCopy";

// Modal for inviting users to this company.
export default function InviteUsersModal(props: Props): Component {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [invitesSent, setInvitesSent] = useState<boolean>(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState<InvitationResponse[]>([]);
  const [failed, setFailed] = useState<InvitationResponse[]>([]);
  const [successful, setSuccessful] = useState<InvitationResponse[]>([]);
  const [formUsers, setFormUsers] = useState<FormUser[]>([]);
  const currentUser = useSelector(getCurrentUser);

  // Initialize formUsers to be one empty user.
  useEffect(() => {
    const newUser: FormUser = {
      formId: 1,
      emailAddress: "",
      roleId: 0,
    };
    setFormUsers([newUser]);
  }, []);

  // Adds a user entry to the Invite Users modal.
  function addUser(): void {
    let formId = 1;
    const formUsersDeepCopy = deepCopy(formUsers);
    formUsersDeepCopy.forEach((user) => {
      if (user.formId >= formId) {
        formId = user.formId + 1;
      }
    });
    const newUser: FormUser = {
      formId: formId,
      emailAddress: "",
      roleId: 0,
    };
    formUsersDeepCopy.push(newUser);
    setFormUsers(formUsersDeepCopy);
  }

  // Updates a user entry when it is changed in the Invite Users modal.
  function updateUser(formId: number, emailAddress: string, roleId: number): void {
    const formUsersDeepCopy = deepCopy(formUsers);
    const formUser = formUsersDeepCopy.find((formUser) => formUser.formId === formId);
    if (formUser !== undefined) {
      formUser.emailAddress = emailAddress;
      formUser.roleId = roleId;
      setFormUsers(formUsersDeepCopy);
    }
  }

  // Deletes a user entry from the Invite Users modal.
  function deleteUser(formId: number): void {
    const formUsersDeepCopy = deepCopy(formUsers);
    const selectedUserIndex = formUsersDeepCopy.findIndex((user) => user.formId === formId);
    if (selectedUserIndex >= 0) {
      formUsersDeepCopy.splice(selectedUserIndex, 1);
      setFormUsers(formUsersDeepCopy);
    }
  }

  // Send email invitations to users.
  async function sendInvitations(): Promise<void> {
    // Confirm that at least one user has been provided to invite.
    if (formUsers.length === 0 || (formUsers.length === 1 && formUsers[0].emailAddress.trim() === "")) {
      setErrorMessage("You must add info for at least one user to invite.");
      return;
    }

    // Confirm that each email is a valid email address and that each role is a valid role ID.
    for (const user of formUsers) {
      const isValidEmail = VALID_EMAIL_REGULAR_EXPRESSION.test(user.emailAddress.toLowerCase());
      if (!isValidEmail) {
        setErrorMessage(`The email address '${user.emailAddress.trim()}' is not valid.`);
        return;
      }

      if (user.roleId === 0) {
        setErrorMessage("All users must have a role selected.");
        return;
      }
    }

    // Structure the data for the API request.
    const inviteUsers = [];
    for (const user of formUsers) {
      const newUser = {
        emailAddress: user.emailAddress,
        roleId: user.roleId,
      };
      inviteUsers.push(newUser);
    }
    const requestBody = {
      inviteUsers: inviteUsers,
      companyId: currentUser.companyId,
    };

    // Send the user email addresses and roles to the API to invite them.
    setLoading(true);
    const [response, responseBody] = (await apiRequest(
      `${API}/registration/company/user/invite`,
      "POST",
      requestBody
    )) as [Response, ResponseBody];
    setLoading(false);

    if (response.ok && responseBody) {
      setInvitesSent(true);
      setSuccessful(responseBody.successfulEmails);
      setAlreadyRegistered(responseBody.alreadyRegistered);
      setFailed(responseBody.failedEmails);
      if (responseBody.alreadyRegistered.length + responseBody.failedEmails.length === 0) {
        setErrorMessage("");
        setSuccessMessage("All invites sent successfully.");
      } else if (responseBody.successfulEmails.length === 0) {
        setErrorMessage("No invites were sent.");
      } else {
        setErrorMessage("");
        setWarningMessage("Some invites could not be sent.");
      }

      responseBody.successfulEmails.forEach((successfulEmail) => {
        if (successfulEmail.roleId !== null) {
          const newUser = {
            userId: successfulEmail.userId,
            name: successfulEmail.emailAddress.split("@")[0],
            emailAddress: successfulEmail.emailAddress,
            phoneNumberCountryCode: null,
            phoneNumber: null,
            roleId: successfulEmail.roleId,
          };

          props.onAction({
            type: USER_TYPES.CREATE_USER,
            payload: {
              user: newUser,
            },
          });
        }
      });
    } else {
      setErrorMessage(await getApiError(response, "Unable to send invitations."));
    }
  }

  // Clean up and exit the modal.
  function exitModal(): void {
    const newUser: FormUser = {
      formId: 1,
      emailAddress: "",
      roleId: 0,
    };
    setFormUsers([newUser]);
    setErrorMessage("");
    setSuccessMessage("");
    setWarningMessage("");
    setInvitesSent(false);
    props.onClose();
  }

  return (
    <div className="user-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="lg"
        animation
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            <span>Invite Users to Company</span>
          </h5>
        </ModalHeader>

        <ModalBody>
          {invitesSent ? (
            <Fragment>
              {successful.length > 0 && (
                <InviteResults
                  title="Successfully sent invites:"
                  color="var(--bs-success)"
                  invitationResponses={successful}
                />
              )}

              {failed.length > 0 && (
                <InviteResults
                  title="Invites that failed to send:"
                  color="var(--bs-danger)"
                  invitationResponses={failed}
                />
              )}

              {alreadyRegistered.length > 0 && (
                <InviteResults
                  title="Invites to users who are already registered:"
                  color="var(--bs-danger)"
                  invitationResponses={alreadyRegistered}
                />
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div className="mx-1">
                <label className="font-weight-bold">Users to Invite</label>
                <button
                  data-test="invite-users-modal-add-user-button"
                  type="submit"
                  className="btn btn-success float-end"
                  onClick={() => addUser()}
                >
                  <span className="d-none d-sm-inline">Add User</span>
                  <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
                </button>
              </div>
              <div className="mt-4">
                {formUsers.map((user) => (
                  <div key={user.formId} className={`${styles.body} my-3 mb-0`}>
                    <div className="row align-items-center">
                      <div className="col">
                        <label>Email Address</label>
                        <input
                          data-test="add-user-email-address-input"
                          className="form-control mx-auto"
                          type="email"
                          value={user.emailAddress}
                          onChange={(e) => updateUser(user.formId, e.target.value, user.roleId)}
                        />
                      </div>
                      <div className="col">
                        <label>Role</label>
                        <select
                          data-test="role-selection"
                          className="form-select"
                          onChange={(e) => updateUser(user.formId, user.emailAddress, parseInt(e.target.value))}
                          required
                        >
                          <option value="">Please select a role...</option>
                          {props.roles.map((role) => (
                            <option key={role.roleId} value={role.roleId}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-auto mt-4">
                        <button
                          data-test="delete-user-invite-button"
                          type="button"
                          className="btn btn-danger float-end"
                          onClick={() => deleteUser(user.formId)}
                        >
                          <i className="d-inline fa fa-fw fa-times fa-xs" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          )}

          {(errorMessage.length > 0 || successMessage.length > 0 || warningMessage.length > 0) && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
                <Success message={successMessage} />
                <Warning message={warningMessage} />
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {invitesSent ? (
            <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
              Close
            </button>
          ) : (
            <Fragment>
              <button
                data-test="invite-users-modal-send-invitation-button"
                className="btn btn-primary"
                type="button"
                onClick={() => sendInvitations()}
              >
                Send Invitations
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

InviteUsersModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

interface Props {
  showModal: boolean;
  roles: Role[];
  onAction: (action: Action) => void;
  onClose: () => void;
}

interface InvitationResponse {
  userId: number;
  emailAddress: string;
  roleId: number;
}

interface ResponseBody {
  successfulEmails: InvitationResponse[];
  alreadyRegistered: InvitationResponse[];
  failedEmails: InvitationResponse[];
  roleId: number | null;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  phoneNumberCountryCode: string | null;
  phoneNumber: string | null;
  roleId: number;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  user: User;
}

interface FormUser {
  formId: number;
  emailAddress: string;
  roleId: number;
}

interface Role {
  roleId: number;
  name: string;
  description: string;
  immutable: boolean;
}
