// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2025-03-15
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import useApi from "../../../hooks/useApi";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import PropTypes from "prop-types";
import ViewAttributeForm from "./ViewAttributeForm/ViewAttributeForm";
import MapHighlightingColorForm from "./MapHighlightingColorForm/MapHighlightingColorForm";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/selectors";
import deepCopy from "../../../utilities/deepCopy";
import IconTooltip from "../../../components/IconTooltip/IconTooltip";
import apiRequest from "../../../utilities/api/apiRequest";
import getApiError from "../../../utilities/api/getApiError";
import {
  API,
  MAX_VIEW_NAME_LENGTH,
  MAX_VIEW_ATTRIBUTES,
  MAX_MAP_HIGHLIGHTING_COLORS,
  VIEW_INITIAL_HIGHLIGHT_COLOR,
} from "../../../constants/miscellaneous";
import DroppableArea from "../../../components/DroppableArea/DroppableArea";
import { VIEW_TYPES } from "../../../constants/reducerActions";
import userHasPermission from "../../../utilities/userHasPermission";
import { Active, DndContext, Over } from "@dnd-kit/core";
import {
  CREATE_GLOBAL_VIEWS_PERMISSION,
  DELETE_GLOBAL_VIEWS_PERMISSION,
  UPDATE_GLOBAL_VIEWS_PERMISSION,
} from "../../../constants/permissions";
import styles from "./ViewModal.module.scss";

// Modal for creating, updating, or deleting a view.
export default function ViewModal(props: Props): Component {
  const initialView: View = {
    viewId: 0,
    name: "",
    shared: false,
    attributes: [],
    mapHighlightingColors: [],
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmExit, setShowConfirmExit] = useState<boolean>(false);
  const [previousView, setPreviousView] = useState<View>(initialView);
  const [name, setName] = useState<string>("");
  const [shared, setShared] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<FormAttribute[]>([]);
  const [mapHighlightingColors, setMapHighlightingColors] = useState<MapHighlightingColor[]>([]);
  const currentUser = useSelector(getCurrentUser);

  // Get detailed view information from the API.
  useApi(
    () => {
      setLoading(props.viewId > 0);
      return props.viewId > 0;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/view/${props.viewId}`,
    },
    async (response: Response, responseBody: GetResponseBody) => {
      if (response.ok && responseBody) {
        const view = {
          viewId: responseBody.viewId,
          name: responseBody.name,
          shared: responseBody.shared,
          attributes: responseBody.attributes,
          mapHighlightingColors: responseBody.mapHighlightingColors,
        };
        responseBody.attributes.forEach((attribute, i) => {
          attribute.formId = i + 1;
          attribute.highlightingRules.forEach((highlightingRule, j) => {
            highlightingRule.formId = j + 1;
          });
        });
        setName(responseBody.name);
        setShared(responseBody.shared);
        setAttributes(responseBody.attributes);
        responseBody.mapHighlightingColors.forEach((mapHighlightingColor, i) => {
          mapHighlightingColor.formId = i + 1;
          mapHighlightingColor.mapHighlightingRules.forEach((mapHighlightingRule, j) => {
            mapHighlightingRule.formId = j + 1;
          });
        });
        setMapHighlightingColors(responseBody.mapHighlightingColors);
        setPreviousView(view);
        setErrorMessage("");
      } else {
        setErrorMessage("Internal server error. Unable to get view settings.");
      }
      setLoading(false);
    },
    [props.viewId]
  );

  // Exit modal if no changes have been made. Otherwise prompt the user.
  function exitModal(): void {
    // Get comparable current and previous attributes.
    const comparableAttributes: ComparableAttribute[] = [];
    attributes.forEach((attribute) => {
      const attributeId = attribute.attributeId;
      attribute.highlightingRules.forEach((highlightingRule) => {
        const flatAttribute = {
          attributeId: attributeId,
          comparator: highlightingRule.comparator,
          valueType: highlightingRule.valueType,
          value: highlightingRule.value,
          colorHexCode: highlightingRule.colorHexCode,
        };
        comparableAttributes.push(flatAttribute);
      });
    });

    const previousComparableAttributes: ComparableAttribute[] = [];
    if (previousView.attributes !== undefined) {
      previousView.attributes.forEach((attribute) => {
        const attributeId = attribute.attributeId;
        attribute.highlightingRules.forEach((highlightingRule) => {
          const flatAttribute = {
            attributeId: attributeId,
            comparator: highlightingRule.comparator,
            valueType: highlightingRule.valueType,
            value: highlightingRule.value,
            colorHexCode: highlightingRule.colorHexCode,
          };
          previousComparableAttributes.push(flatAttribute);
        });
      });
    }

    // Get previous and current map highlighting colors.
    const mapHighlightingColorDeepCopy = deepCopy(mapHighlightingColors);
    mapHighlightingColorDeepCopy.forEach((mapHighlightingColor) => {
      mapHighlightingColor.formId = 0;
      mapHighlightingColor.mapHighlightingRules.forEach((mapHighlightingRule) => {
        mapHighlightingRule.formId = 0;
      });
    });

    const previousMapHighlightingColorsDeepCopy = deepCopy(previousView.mapHighlightingColors);
    previousMapHighlightingColorsDeepCopy.forEach((mapHighlightingColor) => {
      mapHighlightingColor.formId = 0;
      mapHighlightingColor.mapHighlightingRules.forEach((mapHighlightingRule) => {
        mapHighlightingRule.formId = 0;
      });
    });

    // Check to see if anything has changed in the view settings.
    if (
      name === previousView.name &&
      shared === previousView.shared &&
      JSON.stringify(comparableAttributes) === JSON.stringify(previousComparableAttributes) &&
      JSON.stringify(mapHighlightingColorDeepCopy) === JSON.stringify(previousMapHighlightingColorsDeepCopy)
    ) {
      // Since there have been no changes we can safely exit.
      discardChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges(): void {
    setShowConfirmExit(false);
    setErrorMessage("");
  }

  // Exit without saving changes.
  function discardChanges(): void {
    props.onClose();
    setShowConfirmDelete(false);
    setShowConfirmExit(false);
    setErrorMessage("");
  }

  // Add an empty attribute.
  function addAttribute(): void {
    const attributesDeepCopy = deepCopy(attributes);
    let formId = 1;
    attributesDeepCopy.forEach((attribute) => {
      if (attribute.formId >= formId) {
        formId = attribute.formId + 1;
      }
    });
    const attribute = {
      formId: formId,
      attributeId: 0,
      regEnumId: null,
      name: "",
      highlightingRules: [],
      isHighlightable: false,
    };
    attributesDeepCopy.push(attribute);
    setAttributes(attributesDeepCopy);
  }

  // Update an attribute.
  function updateAttribute(formId: number, name: string): void {
    const attributesDeepCopy = deepCopy(attributes);
    const attribute = attributesDeepCopy.find((attribute) => attribute.formId === formId);
    if (attribute !== undefined) {
      const lookUpAttribute = props.attributes.find((lookUpAttribute) => lookUpAttribute.name === name);
      if (lookUpAttribute !== undefined) {
        attribute.attributeId = lookUpAttribute.attributeId;
        attribute.isHighlightable = lookUpAttribute.isHighlightable;
        if (lookUpAttribute.isBoolean || lookUpAttribute.regEnumId !== null) {
          attribute.highlightingRules.forEach((highlightRule) => {
            highlightRule.comparator = "=";
            highlightRule.valueType = "NUMBER";
            highlightRule.value = "0";
          });
        }
      } else {
        attribute.attributeId = 0;
      }
      attribute.name = name;
      setAttributes(attributesDeepCopy);
    }
  }

  // Delete an attribute.
  function deleteAttribute(formId: number): void {
    const attributesDeepCopy = deepCopy(attributes);
    const index = attributesDeepCopy.findIndex((attribute) => attribute.formId === formId);
    if (index >= 0) {
      attributesDeepCopy.splice(index, 1);
      setAttributes(attributesDeepCopy);
    }
  }

  // Add a highlighting rule to an attribute.
  function addRule(attributeFormId: number): void {
    const attributesDeepCopy = deepCopy(attributes);
    const attribute = attributesDeepCopy.find((attribute) => attribute.formId === attributeFormId);

    if (attribute !== undefined) {
      // Get a new form ID.
      let formId = 1;
      attribute.highlightingRules.forEach((highlightingRule) => {
        if (highlightingRule.formId >= formId) {
          formId = highlightingRule.formId + 1;
        }
      });

      // Create the initial highlight rule.
      const newHighlightingRule: HighlightingRule = {
        formId: formId,
        comparator: "=",
        valueType: "NUMBER",
        value: "0",
        colorHexCode: VIEW_INITIAL_HIGHLIGHT_COLOR,
      };

      attribute.highlightingRules.push(newHighlightingRule);
      setAttributes(attributesDeepCopy);
    }
  }

  // Update a highlighting rule in an attribute.
  function updateRule(
    attributeFormId: number,
    highlightingRuleFormId: number,
    comparator: ViewComparator,
    valueType: ViewValueType,
    value: string,
    colorHexCode: string
  ): void {
    const attributesDeepCopy = deepCopy(attributes);
    const attribute = attributesDeepCopy.find((attribute) => attribute.formId === attributeFormId);

    if (attribute !== undefined) {
      const highlightingRuleIndex = attribute.highlightingRules.findIndex(
        (highlightingRule) => highlightingRule.formId === highlightingRuleFormId
      );
      if (highlightingRuleIndex >= 0) {
        const updatedHighlightingRule: HighlightingRule = {
          formId: highlightingRuleFormId,
          comparator: comparator,
          valueType: valueType,
          value: value,
          colorHexCode: colorHexCode,
        };

        // Before updating the highlighting rule, makes sure that based on the value type, specific settings are valid.
        // For each case remove secondary values if they aren't used, and set invalid comparators to a default value.
        enforceValueType(value, valueType, comparator, updatedHighlightingRule);

        attribute.highlightingRules.splice(highlightingRuleIndex, 1, updatedHighlightingRule);
        setAttributes(attributesDeepCopy);
      }
    }
  }

  // In place operation that enforces valid values and comparators for all value types.
  function enforceValueType(
    value: string,
    valueType: ViewValueType,
    comparator: string,
    highlightingRule: GenericHightingRule
  ): void {
    switch (valueType) {
      case "NUMBER":
        if (comparator !== "<>") {
          highlightingRule.value = value.split(",")[0];
        }
        break;
      case "MAX":
        highlightingRule.comparator = "=";
        highlightingRule.value = value.split(",")[0];
        break;
      case "MIN":
        highlightingRule.comparator = "=";
        highlightingRule.value = value.split(",")[0];
        break;
      case "MEDIAN":
        if (comparator === "<>") {
          highlightingRule.comparator = ">";
        }
        highlightingRule.value = value.split(",")[0];
        break;
      case "MEAN":
        if (comparator === "<>") {
          highlightingRule.comparator = ">";
        }
        highlightingRule.value = value.split(",")[0];
        break;
      case "STANDARD_DEVIATION":
        if (comparator === "<>" || comparator === "=") {
          highlightingRule.comparator = ">";
        }
        if (!["1", "2", "3"].includes(value)) {
          highlightingRule.value = "1";
        }
        break;
    }
  }

  // Delete a highlighting rule from an attribute.
  function deleteRule(attributeFormId: number, highlightingRuleFormId: number): void {
    const attributesDeepCopy = deepCopy(attributes);
    const attribute = attributesDeepCopy.find((attribute) => attribute.formId === attributeFormId);

    if (attribute !== undefined) {
      const highlightingRuleIndex = attribute.highlightingRules.findIndex(
        (highlightingRule) => highlightingRule.formId === highlightingRuleFormId
      );
      if (highlightingRuleIndex >= 0) {
        attribute.highlightingRules.splice(highlightingRuleIndex, 1);
        setAttributes(attributesDeepCopy);
      }
    }
  }

  // Check if the current view is valid.
  function viewIsValid(): boolean {
    // List & graph attributes:
    // Check each element of arrays to see if an attribute was incorrectly entered by the user.
    for (const [i, attribute] of attributes.entries()) {
      if (attribute.attributeId === 0) {
        setErrorMessage(`List & graph attribute #${i + 1} does not have a valid attribute name.`);
        return false;
      }

      // Make sure an attribute only has highlighting rules if it is highlightable.
      if (!attribute.isHighlightable && attribute.highlightingRules.length > 0) {
        setErrorMessage(`List & graph attribute #${i + 1} does not support highlighting rules.`);
        return false;
      }

      // Check each highlighting rule to make sure they are valid.
      for (const highlightingRule of attribute.highlightingRules) {
        if (highlightingRule.valueType === "NUMBER") {
          if (highlightingRule.comparator === "<>") {
            const values = highlightingRule.value.split(",");
            const primaryValue = parseFloat(values[0]);
            const secondaryValue = parseFloat(values[1]);
            // If we are checking 'between two values', make sure both values are valid numbers.
            if (!isNaN(primaryValue) && !isNaN(secondaryValue)) {
              // Make sure that the order of the values is correct.
              // For 'between two values' the primary value should be smaller than the secondary value.
              if (primaryValue >= secondaryValue) {
                setErrorMessage(
                  `List & graph attribute #${
                    i + 1
                  } has an invalid highlighting rule. When highlighting between two numbers, ` +
                    `the first number must be smaller than the second number.`
                );
                return false;
              }
            } else {
              setErrorMessage(
                `List & graph attribute #${
                  i + 1
                } has an invalid highlighting rule. When highlighting between two numbers, both numbers must be valid.`
              );
              return false;
            }
          } else if (isNaN(parseFloat(highlightingRule.value))) {
            setErrorMessage(`List & graph attribute #${i + 1} has an invalid highlighting rule.`);
            return false;
          }
        }
      }
    }

    // Map view highlighting rules:
    // Check each element of arrays to see if an attribute was incorrectly entered by the user.
    for (const [i, mapHighlightingColor] of mapHighlightingColors.entries()) {
      // Check each map highlighting rule to make sure they are valid.
      for (const mapHighlightingRule of mapHighlightingColor.mapHighlightingRules) {
        if (mapHighlightingRule.attributeId === 0) {
          setErrorMessage(`Map highlighting rule #${i + 1} does not have a valid attribute name.`);
          return false;
        }
        if (mapHighlightingRule.valueType === "NUMBER") {
          if (mapHighlightingRule.comparator === "<>") {
            const values = mapHighlightingRule.value.split(",");
            const primaryValue = parseFloat(values[0]);
            const secondaryValue = parseFloat(values[1]);
            // If we are checking 'between two values', make sure both values are valid numbers.
            if (!isNaN(primaryValue) && !isNaN(secondaryValue)) {
              // Make sure that the order of the values is correct.
              // For 'between two values' the primary value should be smaller than the secondary value.
              if (primaryValue >= secondaryValue) {
                setErrorMessage(
                  `Map highlighting rule #${i + 1} is invalid. When highlighting between two numbers, ` +
                    `the first number must be smaller than the second number.`
                );
                return false;
              }
            } else {
              setErrorMessage(
                `Map highlighting rule #${
                  i + 1
                } is invalid. When highlighting between two numbers, both numbers must be valid.`
              );
              return false;
            }
          } else if (isNaN(parseFloat(mapHighlightingRule.value))) {
            setErrorMessage(`Map highlighting rule #${i + 1} has an invalid value.`);
            return false;
          }
        }
      }
    }

    if (name.trim().length === 0) {
      setErrorMessage("A view is required to have a name.");
      return false;
    } else if (attributes.length === 0) {
      setErrorMessage("A view must include at least one list & graph attribute.");
      return false;
    } else {
      return true;
    }
  }

  // Create a view.
  async function createView(): Promise<void> {
    if (viewIsValid()) {
      const requestBody = {
        name: name.trim(),
        shared: shared,
        attributes: attributes,
        mapHighlightingColors: mapHighlightingColors,
      };

      setLoading(true);
      const [response, responseBody] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/view`,
        "POST",
        requestBody
      )) as [Response, PostResponseBody];
      setLoading(false);

      if (response.ok) {
        const viewDeepCopy = deepCopy(previousView);
        viewDeepCopy.viewId = responseBody.viewId;
        viewDeepCopy.name = name.trim();
        viewDeepCopy.shared = shared;
        setPreviousView(viewDeepCopy);
        props.onAction({
          type: VIEW_TYPES.CREATE_VIEW,
          payload: {
            view: viewDeepCopy,
          },
        });
        setShowConfirmExit(false);
        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to create view."));
      }
    }
  }

  // Edit a view.
  async function editView(viewId: number): Promise<void> {
    if (viewIsValid()) {
      const requestBody = {
        name: name.trim(),
        shared: shared,
        attributes: attributes,
        mapHighlightingColors: mapHighlightingColors,
      };

      setLoading(true);
      const [response] = (await apiRequest(
        `${API}/company/${currentUser.companyId}/view/${viewId}`,
        "PUT",
        requestBody
      )) as [Response, PutResponseBody];
      setLoading(false);

      if (response.ok) {
        const viewDeepCopy = deepCopy(previousView);
        viewDeepCopy.viewId = viewId;
        viewDeepCopy.name = name.trim();
        viewDeepCopy.shared = shared;
        setPreviousView(viewDeepCopy);
        props.onAction({
          type: VIEW_TYPES.UPDATE_VIEW,
          payload: {
            view: viewDeepCopy,
          },
        });
        setErrorMessage("");
        props.onClose();
      } else {
        setErrorMessage(await getApiError(response, "Unable to update view."));
      }
    }
  }

  // Delete a view.
  async function deleteView(viewId: number): Promise<void> {
    setLoading(true);
    const [response] = (await apiRequest(`${API}/company/${currentUser.companyId}/view/${viewId}`, "DELETE", null)) as [
      Response,
      DeleteResponseBody
    ];
    setLoading(false);

    if (response.ok) {
      discardChanges();
      props.onAction({
        type: VIEW_TYPES.DELETE_VIEW,
        payload: {
          viewId: viewId,
        },
      });
    } else {
      setShowConfirmDelete(false);
      setErrorMessage(await getApiError(response, "Unable to delete view."));
    }
  }

  // Returns whether the current view is allowed to be set to 'shared' with the user's current permissions.
  function viewIsSharable(): boolean {
    return (
      (props.isCreatingNewRecord && userHasPermission([[CREATE_GLOBAL_VIEWS_PERMISSION]])) ||
      (!props.isCreatingNewRecord && previousView.shared && userHasPermission([[UPDATE_GLOBAL_VIEWS_PERMISSION]])) ||
      (!props.isCreatingNewRecord && !previousView.shared && userHasPermission([[CREATE_GLOBAL_VIEWS_PERMISSION]]))
    );
  }

  // Returns whether the current user should be able to edit this view in general.
  function viewIsEditable(): boolean {
    return (
      props.isCreatingNewRecord ||
      (!props.isCreatingNewRecord && !previousView.shared) ||
      (!props.isCreatingNewRecord && previousView.shared && userHasPermission([[UPDATE_GLOBAL_VIEWS_PERMISSION]]))
    );
  }

  // Returns whether the current user can delete this view.
  function viewIsDeletable(): boolean {
    return (
      (!props.isCreatingNewRecord && !previousView.shared) ||
      (!props.isCreatingNewRecord && previousView.shared && userHasPermission([[DELETE_GLOBAL_VIEWS_PERMISSION]]))
    );
  }

  // Add a new map highlighting color.
  function addMapHighlightColor(): void {
    const mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
    let nextFormId = 1;
    mapHighlightingColorsDeepCopy.forEach((mapHighlightingColor) => {
      if (mapHighlightingColor.formId >= nextFormId) {
        nextFormId = mapHighlightingColor.formId + 1;
      }
    });
    mapHighlightingColorsDeepCopy.push({
      formId: nextFormId,
      colorHexCode: VIEW_INITIAL_HIGHLIGHT_COLOR,
      mapHighlightingRules: [
        {
          formId: 1,
          attributeId: 0,
          name: "",
          comparator: "=",
          valueType: "NUMBER",
          value: "",
        },
      ],
    });
    setMapHighlightingColors(mapHighlightingColorsDeepCopy);
  }

  // Add map highlighting rule.
  function addMapHighlightingRule(colorFormId: number): void {
    const mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
    const mapHighlightingColorIndex = mapHighlightingColorsDeepCopy.findIndex(
      (mapHighlightingColor) => mapHighlightingColor.formId === colorFormId
    );
    if (mapHighlightingColorIndex > -1) {
      const selectedMapHighlightingColor = mapHighlightingColorsDeepCopy[mapHighlightingColorIndex];
      let nextFormId = 1;
      selectedMapHighlightingColor.mapHighlightingRules.forEach((mapHighlightingRule) => {
        if (mapHighlightingRule.formId >= nextFormId) {
          nextFormId = mapHighlightingRule.formId + 1;
        }
      });
      selectedMapHighlightingColor.mapHighlightingRules.push({
        formId: nextFormId,
        attributeId: 0,
        name: "",
        comparator: "=",
        valueType: "NUMBER",
        value: "",
      });
      setMapHighlightingColors(mapHighlightingColorsDeepCopy);
    }
  }

  // Update map highlighting color.
  function updateMapHighlightingColor(newColorHexCode: string, colorFormId: number): void {
    const mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
    const mapHighlightingColorIndex = mapHighlightingColorsDeepCopy.findIndex(
      (mapHighlightingColor) => mapHighlightingColor.formId === colorFormId
    );
    if (mapHighlightingColorIndex > -1) {
      mapHighlightingColorsDeepCopy[mapHighlightingColorIndex].colorHexCode = newColorHexCode;
      setMapHighlightingColors(mapHighlightingColorsDeepCopy);
    }
  }

  // Update map highlighting rule.
  function updateMapHighlightingRule(
    colorFormId: number,
    ruleFormId: number,
    name: string,
    nameChanged: boolean,
    comparator: ViewComparator,
    valueType: ViewValueType,
    value: string
  ): void {
    // Find the matching color.
    const mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
    const mapHighlightingColorIndex = mapHighlightingColorsDeepCopy.findIndex(
      (mapHighlightingColor) => mapHighlightingColor.formId === colorFormId
    );
    if (mapHighlightingColorIndex > -1) {
      // find the matching rule.
      const selectedMapHighlightingColor = mapHighlightingColorsDeepCopy[mapHighlightingColorIndex];
      const mapHighlightingRuleIndex = selectedMapHighlightingColor.mapHighlightingRules.findIndex(
        (mapHighlightingRule) => mapHighlightingRule.formId === ruleFormId
      );
      if (mapHighlightingRuleIndex > -1) {
        const selectedMapHighlightingRule = selectedMapHighlightingColor.mapHighlightingRules[mapHighlightingRuleIndex];
        selectedMapHighlightingRule.name = name;
        if (nameChanged) {
          // If the attribute name changed, then we will want to reinitialize the other settings
          // and see if we can match the name with an attribute ID.
          const lookUpAttribute = props.attributes.find(
            (lookUpAttribute) => lookUpAttribute.name === selectedMapHighlightingRule.name
          );
          if (lookUpAttribute !== undefined) {
            selectedMapHighlightingRule.attributeId = lookUpAttribute.attributeId;
            if (lookUpAttribute.isBoolean || lookUpAttribute.regEnumId !== null) {
              selectedMapHighlightingRule.value = "0";
            } else {
              selectedMapHighlightingRule.value = "";
            }
          } else {
            selectedMapHighlightingRule.attributeId = 0;
            selectedMapHighlightingRule.value = "";
          }
          selectedMapHighlightingRule.comparator = "=";
          selectedMapHighlightingRule.valueType = "NUMBER";
        } else {
          // If the name didn't change, we are updating the other rule settings.
          selectedMapHighlightingRule.comparator = comparator;
          selectedMapHighlightingRule.valueType = valueType;
          selectedMapHighlightingRule.value = value;
        }
        // Before updating the highlighting rule, makes sure that based on the value type, specific settings are valid.
        // For each case remove secondary values if they aren't used, and set invalid comparators to a default value.
        enforceValueType(
          selectedMapHighlightingRule.value,
          selectedMapHighlightingRule.valueType,
          selectedMapHighlightingRule.comparator,
          selectedMapHighlightingRule
        );
        setMapHighlightingColors(mapHighlightingColorsDeepCopy);
      }
    }
  }

  // Delete map highlighting color.
  function deleteMapHighlightingColor(colorFormId: number): void {
    const mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
    const mapHighlightingColorIndex = mapHighlightingColorsDeepCopy.findIndex(
      (mapHighlightingColor) => mapHighlightingColor.formId === colorFormId
    );
    if (mapHighlightingColorIndex > -1) {
      mapHighlightingColorsDeepCopy.splice(mapHighlightingColorIndex, 1);
      setMapHighlightingColors(mapHighlightingColorsDeepCopy);
    }
  }

  // Delete map highlighting rule.
  function deleteMapHighlightingRule(colorFormId: number, ruleFormId: number): void {
    const mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
    const mapHighlightingColorIndex = mapHighlightingColorsDeepCopy.findIndex(
      (mapHighlightingColor) => mapHighlightingColor.formId === colorFormId
    );
    if (mapHighlightingColorIndex > -1) {
      const selectedMapHighlightingColor = mapHighlightingColorsDeepCopy[mapHighlightingColorIndex];
      const mapHighlightingRuleIndex = selectedMapHighlightingColor.mapHighlightingRules.findIndex(
        (mapHighlightingRule) => mapHighlightingRule.formId === ruleFormId
      );
      if (mapHighlightingRuleIndex > -1) {
        selectedMapHighlightingColor.mapHighlightingRules.splice(mapHighlightingRuleIndex, 1);
        setMapHighlightingColors(mapHighlightingColorsDeepCopy);
      }
    }
  }

  // Handles changing the order of map highlighting colors when a card is dragged to a new position.
  function handleMapHighlightingColorDragEnd(over: Over | null, active: Active): void {
    if (over !== null) {
      let mapHighlightingColorsDeepCopy = deepCopy(mapHighlightingColors);
      const selectedColorIndex = mapHighlightingColorsDeepCopy.findIndex(
        (mapHighlightingColor) => mapHighlightingColor.formId === active.id
      );
      const selectedColor = mapHighlightingColorsDeepCopy.find(
        (mapHighlightingColor) => mapHighlightingColor.formId === active.id
      );
      if (selectedColorIndex > -1 && selectedColor !== undefined) {
        // Start by removing the color from the list before adding it to its new location.
        mapHighlightingColorsDeepCopy.splice(selectedColorIndex, 1);
        // Figure out where the color gets to added back into the array.
        if (over.id === 0) {
          mapHighlightingColorsDeepCopy = [selectedColor, ...mapHighlightingColorsDeepCopy];
          setMapHighlightingColors(mapHighlightingColorsDeepCopy);
        } else {
          const previousMapHighlightingRuleIndex = mapHighlightingColorsDeepCopy.findIndex(
            (mapHighlightingColor) => mapHighlightingColor.formId === over.id
          );
          if (previousMapHighlightingRuleIndex > -1) {
            mapHighlightingColorsDeepCopy.splice(previousMapHighlightingRuleIndex + 1, 0, selectedColor);
            setMapHighlightingColors(mapHighlightingColorsDeepCopy);
          }
        }
      }
    }
  }

  // Handles changing the order of attribute highlighting rules when a card is dragged to a new position.
  function handleHighlightingRuleDragEnd(attributeFormId: number, over: Over | null, active: Active): void {
    if (over !== null) {
      const attributesDeepCopy = deepCopy(attributes);
      const attributeIndex = attributesDeepCopy.findIndex((attribute) => attribute.formId === attributeFormId);
      if (attributeIndex > -1) {
        // Find the rule inside of the asset that we are trying to move.
        const selectedHighlightingRules = attributesDeepCopy[attributeIndex].highlightingRules;
        const selectedRuleIndex = selectedHighlightingRules.findIndex(
          (highlightingRule) => highlightingRule.formId === active.id
        );
        const selectedRule = selectedHighlightingRules.find(
          (highlightingRule) => highlightingRule.formId === active.id
        );
        if (selectedRuleIndex > -1 && selectedRule !== undefined) {
          // Start by removing the rule from the list before adding it to its new location.
          selectedHighlightingRules.splice(selectedRuleIndex, 1);
          // Figure out where the rule gets to added back into the array.
          if (over.id === 0) {
            attributesDeepCopy[attributeIndex].highlightingRules = [selectedRule, ...selectedHighlightingRules];
            setAttributes(attributesDeepCopy);
          } else {
            const previousMapHighlightingRuleIndex = selectedHighlightingRules.findIndex(
              (mapHighlightingColor) => mapHighlightingColor.formId === over.id
            );
            if (previousMapHighlightingRuleIndex > -1) {
              selectedHighlightingRules.splice(previousMapHighlightingRuleIndex + 1, 0, selectedRule);
              setAttributes(attributesDeepCopy);
            }
          }
        }
      }
    }
  }

  return (
    <div>
      <Spinner loading={loading} />

      <Modal
        show={true}
        onHide={() => exitModal()}
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--modal-z-index)" }}
        size="xl"
        centered
        animation
      >
        <ModalHeader>
          <h5 className="font-weight-bold">{props.isCreatingNewRecord ? "Create View" : "Edit View"}</h5>
        </ModalHeader>

        <ModalBody>
          <div className="mx-2 mt-3">
            <label className="mb-3">Name</label>
            <input
              data-test="view-name-input"
              className="form-control mx-auto mb-4"
              type="text"
              maxLength={MAX_VIEW_NAME_LENGTH}
              value={name}
              disabled={!viewIsEditable()}
              onChange={(e) => setName(e.target.value)}
            />

            {/* Toggle sharable views on or off. */}
            {viewIsSharable() && (
              <div className={`${styles.switch} my-4`}>
                <div className="form-check form-switch">
                  <input
                    data-test="view-is-shared-checkbox"
                    className="form-check-input me-3"
                    type="checkbox"
                    checked={shared}
                    onChange={() => setShared((prev) => !prev)}
                  />
                  {shared ? (
                    <label onClick={() => setShared((prev) => !prev)}>Shared view is enabled</label>
                  ) : (
                    <label onClick={() => setShared((prev) => !prev)}>Shared view is disabled</label>
                  )}
                  <IconTooltip
                    id="sharable-tooltip"
                    icon="info-circle"
                    message={`By default, views are only accessible by the user who created them.
                    Shared views can be used by any user in the company.
                    `}
                    color="var(--info-tooltip)"
                  />
                </div>
              </div>
            )}

            {/* Column & Graph Attributes. */}
            <div>
              <div className="mx-1">
                <label className={styles.title}>
                  <span>List & Graph Attributes&nbsp;</span>
                  <i className="fa fa-fw fa-table" />
                  <i className="fa fa-fw fa-bar-chart me-1" />
                  <IconTooltip
                    id="attribute-tooltip"
                    icon="info-circle"
                    message={`The attributes selected will determine what data to show when looking at the dashboard
                    asset list and dashboard asset graph pages. Attribute values can also be highlighted dynamically
                    based on custom rules. If one value would be highlighted by two separate rules, the rule higher in
                    the list will take priority. Some non-numerical attributes do not support highlighting.
                    `}
                    color="var(--info-tooltip)"
                  />
                </label>

                <button
                  data-test="view-add-attr-button"
                  type="submit"
                  className={`${styles.addButton} btn btn-success float-end`}
                  onClick={() => addAttribute()}
                  disabled={!viewIsEditable() || attributes.length >= MAX_VIEW_ATTRIBUTES}
                >
                  <span className="d-none d-sm-inline">Add Attribute</span>
                  <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
                </button>
              </div>

              {attributes.map((attribute, i) => (
                <ViewAttributeForm
                  key={attribute.formId}
                  formIndex={i}
                  formId={attribute.formId}
                  name={attribute.name}
                  attributes={props.attributes}
                  enumerationMap={props.enumerationMap}
                  highlightingRules={attribute.highlightingRules}
                  disabled={!viewIsEditable()}
                  onChangeAttribute={(name) => {
                    updateAttribute(attribute.formId, name);
                  }}
                  onDeleteAttribute={() => deleteAttribute(attribute.formId)}
                  onAddRule={() => addRule(attribute.formId)}
                  onChangeRule={(formId, comparator, valueType, value, colorHexCode) =>
                    updateRule(attribute.formId, formId, comparator, valueType, value, colorHexCode)
                  }
                  onDeleteRule={(formId) => deleteRule(attribute.formId, formId)}
                  onDragEnd={(over, active) => handleHighlightingRuleDragEnd(attribute.formId, over, active)}
                />
              ))}
            </div>

            {/* Map Highlighting Rules. */}
            <div>
              <div className="mx-1">
                <label className={styles.title}>
                  <span>Map Highlighting Rules&nbsp;</span>
                  <i className="fa fa-fw fa-map-o" />
                  <i className="fa fa-fw fa-map-marker" />
                  <IconTooltip
                    id="map-highlighting-tooltip"
                    icon="info-circle"
                    message={`
                      Asset map markers can have special highlighting rules applied with a view.
                      Rules can be combined to ensure an asset is only highlighted a specific color if all rules apply.
                      If one value would be highlighted by two separate rules, the rule higher in the list will take priority.
                      Some non-numerical attributes cannot be used to enforce rules.
                    `}
                    color="var(--info-tooltip)"
                  />
                </label>

                <button
                  data-test="view-add-map-highlight-color-button"
                  type="submit"
                  className={`${styles.addButton} btn btn-success float-end`}
                  onClick={() => addMapHighlightColor()}
                  disabled={!viewIsEditable() || mapHighlightingColors.length >= MAX_MAP_HIGHLIGHTING_COLORS}
                >
                  <span className="d-none d-sm-inline">Add Rule</span>
                  <i className="d-inline d-sm-none fa fa-fw fa-plus fa-xs" />
                </button>
              </div>
              {mapHighlightingColors.length > 0 && (
                <DndContext onDragEnd={(e) => handleMapHighlightingColorDragEnd(e.over, e.active)}>
                  <DroppableArea id={0} disabled={!viewIsEditable()} />
                  {mapHighlightingColors.map((mapHighlightingColor, i) => (
                    <Fragment key={mapHighlightingColor.formId}>
                      <MapHighlightingColorForm
                        formId={mapHighlightingColor.formId}
                        formIndex={i}
                        disabled={!viewIsEditable()}
                        attributes={props.attributes}
                        enumerationMap={props.enumerationMap}
                        colorHexCode={mapHighlightingColor.colorHexCode}
                        mapHighlightingRules={mapHighlightingColor.mapHighlightingRules}
                        onChangeColor={(colorHexCode) =>
                          updateMapHighlightingColor(colorHexCode, mapHighlightingColor.formId)
                        }
                        onChangeRule={(formId, name, nameChanged, comparator, valueType, value) =>
                          updateMapHighlightingRule(
                            mapHighlightingColor.formId,
                            formId,
                            name,
                            nameChanged,
                            comparator,
                            valueType,
                            value
                          )
                        }
                        onDeleteColor={() => deleteMapHighlightingColor(mapHighlightingColor.formId)}
                        onDeleteRule={(formId) => deleteMapHighlightingRule(mapHighlightingColor.formId, formId)}
                        onAddRule={() => addMapHighlightingRule(mapHighlightingColor.formId)}
                      />
                      <DroppableArea id={mapHighlightingColor.formId} disabled={!viewIsEditable()} />
                    </Fragment>
                  ))}
                </DndContext>
              )}
            </div>

            {errorMessage.length > 0 && (
              <div className="mt-4">
                <Error message={errorMessage} />
              </div>
            )}
          </div>
        </ModalBody>

        <ModalFooter className={styles.footer}>
          {props.isCreatingNewRecord ? (
            <Fragment>
              <button
                data-test="create-view-button"
                className={`${styles.btn} btn btn-primary`}
                type="button"
                onClick={() => createView()}
              >
                Create View
              </button>

              <button
                data-test="cancel-create-view-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              {viewIsDeletable() && (
                <button
                  data-test="delete-view-button"
                  className={`${styles.btn} btn btn-danger me-auto`}
                  type="button"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Delete View
                </button>
              )}

              {viewIsEditable() && (
                <button
                  data-test="save-edit-view-button"
                  className={`${styles.btn} btn btn-primary`}
                  type="button"
                  onClick={() => editView(props.viewId)}
                >
                  Save Changes
                </button>
              )}

              <button
                data-test="cancel-edit-view-button"
                className={`${styles.btn} btn btn-secondary`}
                type="button"
                onClick={() => exitModal()}
              >
                Close
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={showConfirmDelete}
        title={`Delete '${name}'`}
        content={`Are you sure that you want to delete the view '${name}'?`}
        yesText="Delete View"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteView(props.viewId)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => discardChanges()}
      />
    </div>
  );
}

ViewModal.propTypes = {
  isCreatingNewRecord: PropTypes.bool.isRequired,
  viewId: PropTypes.number.isRequired,
  attributes: PropTypes.array.isRequired,
  enumerationMap: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

interface Props {
  isCreatingNewRecord: boolean;
  viewId: number;
  attributes: ViewAttribute[];
  enumerationMap: EnumerationMap;
  onClose: () => void;
  onAction: (action: Action) => void;
}

interface View {
  viewId: number;
  name: string;
  shared: boolean;
  attributes: ViewRuleAttribute[];
  mapHighlightingColors: MapHighlightingColor[];
}

interface ViewRuleAttribute {
  attributeId: number;
  name: string;
  highlightingRules: HighlightingRule[];
}

interface GenericHightingRule {
  formId: number;
  comparator: ViewComparator;
  valueType: ViewValueType;
  value: string;
}

interface HighlightingRule {
  formId: number;
  comparator: ViewComparator;
  valueType: ViewValueType;
  value: string;
  colorHexCode: string;
}

interface ViewAttribute {
  attributeId: number;
  name: string;
  regEnumId: number | null;
  code: string;
  units: string | null;
  isBoolean: boolean;
  isHighlightable: boolean;
}

interface GetResponseBody {
  viewId: number;
  name: string;
  shared: boolean;
  attributes: FormAttribute[];
  mapHighlightingColors: MapHighlightingColor[];
}

interface PostResponseBody {
  viewId: number;
  error?: string;
}

interface PutResponseBody {
  error?: string;
}

interface DeleteResponseBody {
  error?: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  views?: View[];
  view?: View;
  viewId?: number;
}

interface FormAttribute {
  formId: number;
  attributeId: number;
  regEnumId: number | null;
  isHighlightable: boolean;
  name: string;
  highlightingRules: HighlightingRule[];
}

interface ComparableAttribute {
  attributeId: number;
  comparator: ViewComparator;
  valueType: ViewValueType;
  value: string;
  colorHexCode: string;
}

interface MapHighlightingColor {
  formId: number;
  colorHexCode: string;
  mapHighlightingRules: MapHighlightingRules[];
}

interface MapHighlightingRules {
  formId: number;
  attributeId: number;
  name: string;
  comparator: ViewComparator;
  value: string;
  valueType: ViewValueType;
}

interface EnumerationMap {
  [key: string]: EnumerationIndexMap;
}

interface EnumerationIndexMap {
  [key: string]: string;
}

type ViewValueType = "NUMBER" | "MEDIAN" | "MEAN" | "STANDARD_DEVIATION" | "MAX" | "MIN";

type ViewComparator = "=" | "!=" | "<" | ">" | "<>";
