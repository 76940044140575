// --------------------------------------------------------------
// Created On: 2023-02-27
// Author: Zachary Thomas
//
// Last Modified: 2025-03-03
// Modified By: Zachary Thomas
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState } from "react";
import Modal from "../Modal/Modal";
import PropTypes from "prop-types";
import styles from "./IconPickerMapMarker.module.scss";

// Interactive icon picker. Allows a selection of one icon from an array of icons.
// The interactive selector is shown as a map marker.
export default function IconPickerMapMarker(props: Props): Component {
  const [showModal, setShowModal] = useState<boolean>(false);

  // Select an icon.
  function selectIcon(assetMapIconId: number): void {
    setShowModal(false);
    props.onChange(assetMapIconId);
  }

  // Gets an asset map icon's image URL given its ID. If no icon can be found, return a map marker with no icon.
  function getAssetMapIconUrl(assetMapIconId: number | null, assetMapIcons: AssetMapIcon[]): string {
    const assetMapIcon = assetMapIcons.find((assetMapIcon) => assetMapIcon.assetMapIconId === assetMapIconId);
    if (assetMapIcon !== undefined) {
      return `/mapMarkers/mapMarker${assetMapIcon.code}Active.png`;
    } else {
      return "/mapMarkers/mapMarkerColors/default.png";
    }
  }

  return (
    <div>
      <div
        className={styles.markerWrapper}
        onClick={() => {
          props.disabled ? setShowModal(false) : setShowModal(true);
        }}
      >
        <img
          src={getAssetMapIconUrl(props.selectedAssetMapIconId, props.assetMapIcons)}
          alt="Icon selector for map marker"
          className={styles.marker}
        />
        <i className="fa fa-fw fa-angle-down fa-lg" />
      </div>
      <Modal
        backdropClassName={`${styles.modal} ${styles.backdrop}`}
        style={{ zIndex: "var(--super-modal-z-index)" }}
        show={showModal}
        onHide={() => setShowModal(false)}
        size="sm"
        centered
      >
        <div className="m-3">
          <div className="row justify-content-center">
            {props.assetMapIcons.map((assetMapIcon) => (
              <div className="col-4" key={assetMapIcon.assetMapIconId}>
                <div
                  className={`${styles.markerWrapper} mx-auto my-2`}
                  onClick={() => selectIcon(assetMapIcon.assetMapIconId)}
                >
                  <img
                    src={getAssetMapIconUrl(assetMapIcon.assetMapIconId, props.assetMapIcons)}
                    alt="Icon selector option for map marker"
                    className={styles.marker}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}

IconPickerMapMarker.propTypes = {
  assetMapIcons: PropTypes.array.isRequired,
  selectedAssetMapIconId: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

interface Props {
  assetMapIcons: AssetMapIcon[];
  selectedAssetMapIconId: number | null;
  disabled?: boolean;
  onChange: (selectedAssetMapIconId: number | null) => void;
}

interface AssetMapIcon {
  assetMapIconId: number;
  name: string;
  code: string;
}
