// --------------------------------------------------------------
// Created On: 2022-01-04
// Author: Zachary Thomas
//
// Last Modified: 2025-03-13
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useEffect, useState, useReducer } from "react";
import useApi from "../../hooks/useApi";
import MaintenanceList from "./MaintenanceList/MaintenanceList";
import { MAINTENANCE_LOG_TYPES } from "../../constants/reducerActions";
import { useParams } from "react-router-dom";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import { API } from "../../constants/miscellaneous";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import deepCopy from "../../utilities/deepCopy";
import MaintenanceModal from "./MaintenanceModal/MaintenanceModal";

// Page that supports creating, updating, and deleting maintenance logs for the current asset.
export default function AssetMaintenanceLogsPage(): Component {
  const initialMaintenanceLog = {
    assetMaintenanceLogId: 0,
    datePerformed: "",
    workPerformedBy: "",
    workPerformed: "",
    currentServiceRuntime: "",
    engineRuntime: "",
    vibrationBasedRuntime: "",
    cost: "",
    note: "",
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>("");
  const [isRented, setIsRented] = useState<boolean>(false);
  const [hoursSinceLastServiced, setHoursSinceLastServiced] = useState<string>("");
  const [vibrationBasedHours, setVibrationBasedHours] = useState<string>("");
  const [assetHours, setAssetHours] = useState<string>("");
  const [mode, setMode] = useState<"create" | "edit">("create");
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedLog, setSelectedLog] = useState<MaintenanceLog>(initialMaintenanceLog);
  const [maintenanceLogs, dispatch] = useReducer(maintenanceLogReducer, []);
  const currentUser = useSelector(getCurrentUser);
  const { assetId } = useParams();

  // Get the list of maintenance logs.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/asset/${assetId}/maintenance-log`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        setAssetName(responseBody.assetName);
        dispatch({
          type: MAINTENANCE_LOG_TYPES.SET_MAINTENANCE_LOGS,
          payload: {
            maintenanceLogs: responseBody.maintenanceLogs,
          },
        });
        if (responseBody.isRented !== undefined) {
          setIsRented(responseBody.isRented);
        }
        if (responseBody.hoursSinceLastServiced !== undefined && responseBody.hoursSinceLastServiced !== null) {
          setHoursSinceLastServiced(responseBody.hoursSinceLastServiced);
        } else {
          setHoursSinceLastServiced("");
        }
        if (responseBody.vibrationBasedHours !== undefined && responseBody.vibrationBasedHours !== null) {
          setVibrationBasedHours(responseBody.vibrationBasedHours);
        } else {
          setVibrationBasedHours("");
        }
        if (responseBody.assetHours !== undefined && responseBody.assetHours !== null) {
          setAssetHours(responseBody.assetHours);
        } else {
          setAssetHours("");
        }
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    [assetId]
  );

  // If the selected ID changes, update the selected maintenance log, and mode.
  useEffect(() => {
    const index = maintenanceLogs.findIndex((maintenanceLog) => maintenanceLog.assetMaintenanceLogId === selectedId);

    if (index === -1) {
      setSelectedLog({
        assetMaintenanceLogId: 0,
        datePerformed: "",
        workPerformedBy: "",
        workPerformed: "",
        currentServiceRuntime: hoursSinceLastServiced,
        engineRuntime: assetHours,
        vibrationBasedRuntime: vibrationBasedHours,
        cost: "",
        note: "",
      });
    } else {
      setSelectedLog(maintenanceLogs[index]);
    }

    if (selectedId === 0) {
      setMode("create");
    } else {
      setMode("edit");
    }
  }, [selectedId, hoursSinceLastServiced, assetHours, vibrationBasedHours, JSON.stringify(maintenanceLogs)]);

  // Maintenance log reducer.
  function maintenanceLogReducer(state: MaintenanceLog[], action: Action): MaintenanceLog[] {
    switch (action.type) {
      case MAINTENANCE_LOG_TYPES.SET_MAINTENANCE_LOGS: {
        // Convert times from UTC to local time.
        if (action.payload.maintenanceLogs !== undefined) {
          let maintenanceLogs = action.payload.maintenanceLogs;
          maintenanceLogs = sortMaintenanceLogs(maintenanceLogs);
          return maintenanceLogs;
        } else {
          return state;
        }
      }

      case MAINTENANCE_LOG_TYPES.CREATE_MAINTENANCE_LOG: {
        let stateShallowCopy = state;

        // Check if the log already exists before creating it.
        if (action.payload.maintenanceLog !== undefined) {
          const newMaintenanceLog = action.payload.maintenanceLog;
          const maintenanceLogExists = stateShallowCopy.some(
            (maintenanceLog) => maintenanceLog.assetMaintenanceLogId === newMaintenanceLog.assetMaintenanceLogId
          );
          if (!maintenanceLogExists) {
            stateShallowCopy.push(newMaintenanceLog);
            stateShallowCopy = sortMaintenanceLogs(stateShallowCopy);
          }
        }

        return stateShallowCopy;
      }

      case MAINTENANCE_LOG_TYPES.UPDATE_MAINTENANCE_LOG: {
        let stateDeepCopy = deepCopy(state);

        if (action.payload.maintenanceLog !== undefined) {
          const updatedMaintenanceLog = action.payload.maintenanceLog;

          const maintenanceLogIndex = state.findIndex(
            (maintenanceLog) => maintenanceLog.assetMaintenanceLogId === updatedMaintenanceLog.assetMaintenanceLogId
          );
          if (maintenanceLogIndex > -1) {
            stateDeepCopy.splice(maintenanceLogIndex, 1, updatedMaintenanceLog);
            stateDeepCopy = sortMaintenanceLogs(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case MAINTENANCE_LOG_TYPES.DELETE_MAINTENANCE_LOG: {
        const stateDeepCopy = deepCopy(state);
        const maintenanceLogIndex = state.findIndex(
          (maintenanceLog) => maintenanceLog.assetMaintenanceLogId === action.payload.assetMaintenanceLogId
        );
        if (maintenanceLogIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(maintenanceLogIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }

  // Sort maintenance logs by date and time.
  function sortMaintenanceLogs(maintenanceLogs: MaintenanceLog[]): MaintenanceLog[] {
    return maintenanceLogs.sort((a, b) => {
      const dateA = a.datePerformed;
      const dateB = b.datePerformed;
      if (dateA < dateB) {
        return -1;
      } else if (dateA > dateB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="p-4">
      <Spinner loading={loading} />

      <MaintenanceList
        assetName={assetName}
        maintenanceLogs={maintenanceLogs}
        onSelect={(selectedId) => setSelectedId(selectedId)}
      />

      <MaintenanceModal
        mode={mode}
        showModal={selectedId !== -1}
        isRented={isRented}
        selectedLog={selectedLog}
        hoursSinceLastServiced={mode === "create" ? hoursSinceLastServiced : selectedLog.currentServiceRuntime || ""}
        vibrationBasedHours={mode === "create" ? vibrationBasedHours : selectedLog.vibrationBasedRuntime || ""}
        assetHours={mode === "create" ? assetHours : selectedLog.engineRuntime || ""}
        onClose={() => setSelectedId(-1)}
        onAction={(action) => dispatch(action)}
        onClearServiceHours={() => setHoursSinceLastServiced("0.0")}
      />
    </div>
  );
}

interface ResponseBody {
  assetName: string;
  isRented: boolean;
  hoursSinceLastServiced: string | null;
  vibrationBasedHours: string | null;
  assetHours: string | null;
  maintenanceLogs: MaintenanceLog[];
}

interface MaintenanceLog {
  assetMaintenanceLogId: number;
  workPerformed: string;
  workPerformedBy: string;
  datePerformed: string;
  currentServiceRuntime: string | null;
  vibrationBasedRuntime: string | null;
  engineRuntime: string | null;
  cost: string;
  note: string;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assetMaintenanceLogId?: number;
  maintenanceLog?: MaintenanceLog;
  maintenanceLogs?: MaintenanceLog[];
}
