// --------------------------------------------------------------
// Created On: 2023-07-20
// Author: Zachary Thomas
//
// Last Modified: 2025-03-26
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, Fragment } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import PropTypes from "prop-types";
import { BREAKPOINT_EXTRA_EXTRA_LARGE, BREAKPOINT_EXTRA_LARGE, BREAKPOINT_SMALL } from "../../../constants/breakpoints";
import {
  CONTROLLER_APP_VFD,
  CONTROLLER_APP_SOFT_START,
  CONTROLLER_APP_MODBUS_SENSOR,
  CONTROLLER_WORLDWIDE_SOFT_START,
  CONTROLLER_ACS580,
  CONTROLLER_ACS550,
  COPILOT_DEVICE_TYPE,
  COPILOT_EDGE_DEVICE_TYPE,
  COPILOT_PMG_DEVICE_TYPE,
  DEVICES_THAT_SUPPORT_REMOTE_OPERATIONS,
} from "../../../constants/miscellaneous";
import { LOCK_REMOTE_CONTROL_PERMISSION } from "../../../constants/permissions";
import userHasPermission from "../../../utilities/userHasPermission";
import SpeedChangeButton from "./SpeedChangeButton/SpeedChangeButton";
import InfoModal from "../../../components/InfoModal/InfoModal";
import RemoteOperationDisclaimer from "./RemoteOperationDisclaimer/RemoteOperationDisclaimer";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import styles from "./RemoteOperationPanel.module.scss";

// Panel for remotely operating an asset.
export default function RemoteOperationPanel(props: Props): Component {
  const windowDimensions = useWindowDimensions();
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const [manualRpm, setManualRpm] = useState<string>("");
  const [showClearModal, setShowClearModal] = useState<boolean>(false);
  const [showRestartModal, setShowRestartModal] = useState<boolean>(false);
  const [showReapplyModal, setShowReapplyModal] = useState<boolean>(false);

  // Apply the manual RPM value if it is a valid number.
  function applyManualRpm(rpm: string): void {
    rpm = rpm.trim();
    if (rpm.length === 0) {
      props.onError("Invalid RPM entered.");
      return;
    }
    const rpmNumber = parseInt(rpm, 10);
    if (Number.isNaN(rpmNumber)) {
      props.onError("Entered RPM is not an integer.");
      return;
    } else if (rpmNumber < 0) {
      props.onError("Entered RPM must be a positive number.");
      return;
    }
    props.onChangeSpeed(rpmNumber);
  }

  return (
    <div className={styles.body}>
      <div className={styles.titleBar}>
        <label className={styles.title}>{props.assetName} Remote Operation</label>
      </div>

      {/* Many remote operations require a controller. */}
      {props.controllerModel !== null && (
        <Fragment>
          {/* Special controls for the ACS580 and ACS550 VFDs. */}
          {[CONTROLLER_ACS580, CONTROLLER_ACS550].includes(props.controllerModel) && (
            <div className="container">
              <label className={styles.controlLabel}>Communication Mode</label>
              <div className="row align-items-center">
                <div className={`col ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => props.onChangeCommunicationMode("Modbus Control")}
                  >
                    Modbus
                  </button>
                </div>
                <div className={`col ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => props.onChangeCommunicationMode("Analog/Digital Control")}
                  >
                    Analog/Digital
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Special controls for the Worldwide Soft Start. */}
          {props.controllerModel === CONTROLLER_WORLDWIDE_SOFT_START && (
            <div className="container">
              <label className={styles.controlLabel}>Communication Mode</label>
              <div className="row align-items-center">
                <div className={`col ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => props.onChangeCommunicationMode("Remote")}
                  >
                    Remote
                  </button>
                </div>
                <div className={`col ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => props.onChangeCommunicationMode("Local")}
                  >
                    Local
                  </button>
                </div>
              </div>
            </div>
          )}

          {props.controllerApplicationType !== CONTROLLER_APP_MODBUS_SENSOR && (
            <div className="container">
              <label className={styles.controlLabel}>Running Status</label>
              <div className="row align-items-center">
                {props.controllerModel !== CONTROLLER_WORLDWIDE_SOFT_START && (
                  <div className={`col px-3 ${styles.controlColumn}`}>
                    <button
                      className="btn btn-success w-100"
                      type="button"
                      disabled={props.loading}
                      onClick={() => props.onChangeRunningStatus("ON")}
                    >
                      ON
                    </button>
                  </div>
                )}
                <div className={`col px-3 ${styles.controlColumn}`}>
                  <button
                    className="btn btn-danger w-100"
                    type="button"
                    disabled={props.loading}
                    onClick={() => props.onChangeRunningStatus("OFF")}
                  >
                    OFF
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Soft starts / modbus sensors do not support speed change. */}
          {props.controllerApplicationType !== CONTROLLER_APP_SOFT_START &&
            props.controllerApplicationType !== CONTROLLER_APP_MODBUS_SENSOR && (
              <div className="container">
                <label className={styles.controlLabel}>Speed Change</label>

                {/* Speed controls for VFDs */}
                {props.controllerApplicationType === CONTROLLER_APP_VFD && (
                  <div className="row align-items-center">
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1000}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1200}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1300}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1400}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1500}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1600}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1800}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={2000}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                  </div>
                )}

                {/* Speed controls for non-VFD applications. */}
                {props.controllerApplicationType !== CONTROLLER_APP_VFD && (
                  <div className="row align-items-center">
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={800}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1000}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1200}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1300}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1400}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1500}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1600}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                    <div className={`col-6 col-lg-3 px-3 ${styles.controlColumn}`}>
                      <SpeedChangeButton
                        rpmValue={1800}
                        showHertz={false}
                        disabled={props.loading}
                        onClick={(rpm) => props.onChangeSpeed(rpm)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

          {/* Soft starts / modbus sensors do not support speed change. */}
          {props.controllerApplicationType !== CONTROLLER_APP_SOFT_START &&
            props.controllerApplicationType !== CONTROLLER_APP_MODBUS_SENSOR && (
              <div className="container">
                <div className="row">
                  <div className="col-9 g-0 ps-3">
                    <input
                      className={`${styles.manualRpm} align-self-center form-control`}
                      type="text"
                      maxLength={10}
                      value={manualRpm}
                      onChange={(e) => setManualRpm(e.target.value)}
                    />
                  </div>
                  <div className="col-3 g-0 pe-3">
                    <button
                      className={`${styles.manualRpmButton} align-self-center btn btn-primary`}
                      type="button"
                      disabled={props.loading}
                      onClick={() => applyManualRpm(manualRpm)}
                    >
                      {windowDimensions.width > BREAKPOINT_SMALL &&
                      (windowDimensions.width < BREAKPOINT_EXTRA_LARGE ||
                        windowDimensions.width > BREAKPOINT_EXTRA_EXTRA_LARGE)
                        ? "Set RPM"
                        : "RPM"}
                    </button>
                  </div>
                </div>
              </div>
            )}
        </Fragment>
      )}

      {DEVICES_THAT_SUPPORT_REMOTE_OPERATIONS.includes(props.deviceType || "") && (
        <div className="container">
          <label className={styles.controlLabel}>Monitoring Device Operations</label>

          {[COPILOT_DEVICE_TYPE, COPILOT_PMG_DEVICE_TYPE].includes(props.deviceType || "") && (
            <Fragment>
              <div className="row align-items-center">
                <div className={`col px-3 ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    disabled={props.loading}
                    onClick={() => setShowClearModal(true)}
                  >
                    {windowDimensions.width > BREAKPOINT_SMALL ? "Clear Device" : "Clear"}
                  </button>
                </div>

                <div className={`col px-3 ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    disabled={props.loading}
                    onClick={() => setShowRestartModal(true)}
                  >
                    {windowDimensions.width > BREAKPOINT_SMALL ? "Restart Device" : "Restart"}
                  </button>
                </div>
              </div>

              <div className="row align-items-center">
                <div className={`col px-3 ${styles.controlColumn}`}>
                  <button
                    className="btn btn-primary w-100"
                    type="button"
                    disabled={props.loading}
                    onClick={() => setShowReapplyModal(true)}
                  >
                    {windowDimensions.width > BREAKPOINT_SMALL ? "Reapply Configuration" : "Reapply"}
                  </button>
                </div>
              </div>
            </Fragment>
          )}

          {props.deviceType === COPILOT_EDGE_DEVICE_TYPE && (
            <div className="row align-items-center">
              <div className={`col px-3 ${styles.controlColumn}`}>
                <button
                  className="btn btn-primary w-100"
                  type="button"
                  disabled={props.loading}
                  onClick={() => props.onBlinkLeds()}
                >
                  Blink LEDs
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {userHasPermission([[LOCK_REMOTE_CONTROL_PERMISSION]]) && (
        <div className="container">
          <label className={styles.controlLabel}>Remote Lock</label>
          <div className="row align-items-center">
            <div className={`col px-3 ${styles.controlColumn}`}>
              {!props.isLockedOut && (
                <button
                  className="btn btn-danger w-100"
                  type="button"
                  disabled={props.loading}
                  onClick={() => props.onChangeLock(true)}
                >
                  {windowDimensions.width > BREAKPOINT_SMALL ? "Lock Remote Control" : "Lock"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <ConfirmModal
        showModal={showClearModal}
        title={`Clear failed commands?`}
        content={
          "Clearing failed commands on the monitoring device may resolve issues where new commands are failing to be received. " +
          "Do you want to clear failed commands?"
        }
        yesText="Clear Failed Commands"
        noText="Cancel"
        danger={true}
        onClose={() => setShowClearModal(false)}
        onYes={() => {
          props.onClearFailedCommands();
          setShowClearModal(false);
        }}
        onNo={() => setShowClearModal(false)}
      />

      <ConfirmModal
        showModal={showRestartModal}
        title={`Restart monitoring device?`}
        content={
          "Restarting the monitoring device may resolve issues where new commands are failing to be received. " +
          "The monitoring device will not be accessible and will not report data while it is restarting. " +
          "Do you want to restart the monitoring device?"
        }
        yesText="Restart Monitoring Device"
        noText="Cancel"
        danger={true}
        onClose={() => setShowRestartModal(false)}
        onYes={() => {
          props.onRestartMonitoringDevice();
          setShowRestartModal(false);
        }}
        onNo={() => setShowRestartModal(false)}
      />

      <ConfirmModal
        showModal={showReapplyModal}
        title="Reapply Configuration?"
        content={
          "Reapplying a configuration will overwrite the current configuration on the device to match the current asset details. " +
          "You will get a notification when the process is complete. " +
          "Would you like to reapply the configuration?"
        }
        yesText="Reapply Configuration"
        noText="Cancel"
        danger={true}
        onClose={() => setShowReapplyModal(false)}
        onYes={() => {
          props.onReapplyConfiguration();
          setShowReapplyModal(false);
        }}
        onNo={() => setShowReapplyModal(false)}
      />

      <label className={styles.controlLabel}>
        <InfoModal
          showModal={showDisclaimer}
          title="Remote Operation Disclaimer"
          linkMessage="(Remote Operation Disclaimer)"
          darkBackground={true}
          onClick={() => setShowDisclaimer(true)}
          onClose={() => setShowDisclaimer(false)}
        >
          <RemoteOperationDisclaimer />
        </InfoModal>
      </label>
    </div>
  );
}

RemoteOperationPanel.propTypes = {
  assetName: PropTypes.string.isRequired,
  controllerApplicationType: PropTypes.string,
  controllerModel: PropTypes.string,
  deviceType: PropTypes.string,
  isLockedOut: PropTypes.bool.isRequired,
  canUnlock: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onChangeCommunicationMode: PropTypes.func.isRequired,
  onChangeSpeed: PropTypes.func.isRequired,
  onChangeRunningStatus: PropTypes.func.isRequired,
  onClearFailedCommands: PropTypes.func.isRequired,
  onRestartMonitoringDevice: PropTypes.func.isRequired,
  onReapplyConfiguration: PropTypes.func.isRequired,
  onBlinkLeds: PropTypes.func.isRequired,
  onChangeLock: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

interface Props {
  assetName: string;
  controllerApplicationType: string | null;
  controllerModel: string | null;
  deviceType: string | null;
  isLockedOut: boolean;
  canUnlock: boolean;
  loading: boolean;
  onChangeCommunicationMode: (command: string) => void;
  onChangeSpeed: (command: number) => void;
  onChangeRunningStatus: (command: string) => void;
  onClearFailedCommands: () => void;
  onRestartMonitoringDevice: () => void;
  onReapplyConfiguration: () => void;
  onBlinkLeds: () => void;
  onChangeLock: (isLocked: boolean) => void;
  onError: (error: string) => void;
}
