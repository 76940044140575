// --------------------------------------------------------------
// Created On: 2022-05-03
// Author: Zachary Thomas
//
// Last Modified: 2025-02-27
// Modified By: Dimitra Weinstein
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import NavbarLink from "../NavbarLink/NavbarLink";
import NavbarAssetLink from "./NavbarAssetLink/NavbarAssetLink";
import PropTypes from "prop-types";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { RPM_COMPANY, PUBLIC_PAGES } from "../../constants/miscellaneous";
import userHasPermission from "../../utilities/userHasPermission";
import {
  ALIAS_ATTRIBUTES_PERMISSION,
  CREATE_ASSETS_PERMISSION,
  CREATE_ASSET_GROUPS_PERMISSION,
  CREATE_ASSET_RENTALS_PERMISSION,
  CREATE_REPORTS_PERMISSION,
  DELETE_ASSETS_PERMISSION,
  DELETE_ASSET_GROUPS_PERMISSION,
  REVOKE_ASSET_RENTALS_PERMISSION,
  RETURN_ASSET_RENTALS_PERMISSION,
  DELETE_REPORTS_PERMISSION,
  MANAGE_ALERT_THRESHOLDS_PERMISSION,
  UPDATE_ASSETS_PERMISSION,
  UPDATE_ASSET_GROUPS_PERMISSION,
  UPDATE_ASSET_RENTALS_PERMISSION,
  UPDATE_REPORTS_PERMISSION,
  VIEW_DEVICE_STATUSES_PERMISSION,
  VIEW_SUBSCRIPTIONS_PERMISSION,
  ACCEPT_ASSET_RENTALS_PERMISSION,
  DECLINE_ASSET_RENTALS_PERMISSION,
  TRANSFER_ASSETS_PERMISSION,
  ACCEPT_ASSET_TRANSFERS_PERMISSION,
  DECLINE_ASSET_TRANSFERS_PERMISSION,
  CREATE_TEMPLATES_PERMISSION,
  UPDATE_TEMPLATES_PERMISSION,
  DELETE_TEMPLATES_PERMISSION,
  CONFIGURE_CONTROLLERS_PERMISSION,
  VIEW_GLOBAL_RPM_REPORTS_PERMISSION,
  CREATE_COMPANY_PERMISSION,
  UPDATE_COMPANY_PERMISSION,
  DELETE_COMPANY_PERMISSION,
} from "../../constants/permissions";
import styles from "./NavbarChild.module.scss";
import userHasUserPermissions from "../../utilities/userHasUserPermissions";
import userHasRolePermissions from "../../utilities/userHasRolePermissions";

// Child navbar that contains options related to the primary navbar.
export default function NavbarChild(props: Props): Component {
  const [inCompany, setInCompany] = useState<boolean>(true);
  const currentUser = useSelector(getCurrentUser);

  // Keep track of the status of the current user for routing purposes.
  useEffect(() => {
    setInCompany(currentUser.companyName !== RPM_COMPANY);
  }, [JSON.stringify(currentUser)]);

  return PUBLIC_PAGES.includes(props.currentPage) ? null : (
    <nav className={`${styles.navbarChild} navbar navbar-expand-xl navbar-custom d-none d-xl-flex px-3 py-2`}>
      <Routes>
        {!currentUser.isPackager && inCompany && (
          <Route
            path="/*"
            element={
              <Fragment>
                <NavbarLink name="Asset Map" targetPage="/dashboard/map" currentPage={props.fullPath} />
                <NavbarLink name="Asset List" targetPage="/dashboard/list" currentPage={props.fullPath} />
                <NavbarLink name="Asset Graph" targetPage="/dashboard/graph" currentPage={props.fullPath} />
              </Fragment>
            }
          />
        )}
        {!inCompany && (
          <Route
            path="/*"
            element={
              <Fragment>
                <NavbarLink name="Companies" targetPage="/dashboard/companies" currentPage={props.fullPath} />
                {userHasPermission([[VIEW_GLOBAL_RPM_REPORTS_PERMISSION]]) && (
                  <NavbarLink name="Chatty Assets" targetPage="/assets/chatty" currentPage={props.fullPath} />
                )}
                {userHasPermission([
                  [CREATE_COMPANY_PERMISSION],
                  [UPDATE_COMPANY_PERMISSION],
                  [DELETE_COMPANY_PERMISSION],
                ]) && (
                  <NavbarLink name="Manage Companies" targetPage="/manage/companies" currentPage={props.fullPath} />
                )}
                {userHasUserPermissions() && (
                  <NavbarLink name="Manage Users" targetPage="/manage/users" currentPage={props.fullPath} />
                )}
                {userHasRolePermissions() && (
                  <NavbarLink name="Manage Roles" targetPage="/manage/roles" currentPage={props.fullPath} />
                )}
                {userHasPermission([[VIEW_GLOBAL_RPM_REPORTS_PERMISSION]]) && (
                  <NavbarLink name="Reports" targetPage="/reports" currentPage={props.fullPath} />
                )}
              </Fragment>
            }
          />
        )}

        {inCompany && (
          <Fragment>
            <Route
              path="/manage/*"
              element={
                <Fragment>
                  {userHasPermission([
                    [CREATE_ASSETS_PERMISSION],
                    [UPDATE_ASSETS_PERMISSION],
                    [DELETE_ASSETS_PERMISSION],
                    [CREATE_ASSET_GROUPS_PERMISSION],
                    [UPDATE_ASSET_GROUPS_PERMISSION],
                    [DELETE_ASSET_GROUPS_PERMISSION],
                  ]) && <NavbarLink name="Assets" targetPage="/manage/assets" currentPage={props.fullPath} />}

                  {userHasPermission([
                    [CREATE_TEMPLATES_PERMISSION],
                    [UPDATE_TEMPLATES_PERMISSION],
                    [DELETE_TEMPLATES_PERMISSION],
                  ]) && <NavbarLink name="Templates" targetPage="/manage/templates" currentPage={props.fullPath} />}

                  {userHasUserPermissions() && (
                    <NavbarLink name="Users" targetPage="/manage/users" currentPage={props.fullPath} />
                  )}

                  {userHasRolePermissions() && (
                    <NavbarLink name="Roles" targetPage="/manage/roles" currentPage={props.fullPath} />
                  )}

                  {userHasPermission([
                    [CREATE_REPORTS_PERMISSION],
                    [UPDATE_REPORTS_PERMISSION],
                    [DELETE_REPORTS_PERMISSION],
                  ]) && <NavbarLink name="Reports" targetPage="/manage/reports" currentPage={props.fullPath} />}

                  {userHasPermission([
                    [CREATE_ASSET_RENTALS_PERMISSION],
                    [UPDATE_ASSET_RENTALS_PERMISSION],
                    [REVOKE_ASSET_RENTALS_PERMISSION],
                    [RETURN_ASSET_RENTALS_PERMISSION],
                    [ACCEPT_ASSET_RENTALS_PERMISSION],
                    [DECLINE_ASSET_RENTALS_PERMISSION],
                  ]) && <NavbarLink name="Rentals" targetPage="/manage/rentals" currentPage={props.fullPath} />}

                  {userHasPermission([
                    [TRANSFER_ASSETS_PERMISSION],
                    [ACCEPT_ASSET_TRANSFERS_PERMISSION],
                    [DECLINE_ASSET_TRANSFERS_PERMISSION],
                  ]) && <NavbarLink name="Transfers" targetPage="/manage/transfers" currentPage={props.fullPath} />}

                  <NavbarLink name="Views" targetPage="/manage/views" currentPage={props.fullPath} />
                </Fragment>
              }
            />

            <Route
              path="/asset/:assetId/*"
              element={
                <Fragment>
                  <NavbarAssetLink name="Data" targetPage="/asset/*/data" currentPage={props.fullPath} />
                  <NavbarAssetLink name="Graph" targetPage="/asset/*/graph" currentPage={props.fullPath} />
                  <NavbarAssetLink name="Map" targetPage="/asset/*/map" currentPage={props.fullPath} />
                  <NavbarAssetLink
                    name="Remote Operation"
                    targetPage="/asset/*/operation"
                    currentPage={props.fullPath}
                  />
                  {userHasPermission([[CONFIGURE_CONTROLLERS_PERMISSION]]) && (
                    <NavbarAssetLink name="Controller" targetPage="/asset/*/controller" currentPage={props.fullPath} />
                  )}
                  <NavbarAssetLink name="Documents" targetPage="/asset/*/documents" currentPage={props.fullPath} />
                  <NavbarAssetLink name="Maintenance Logs" targetPage="/asset/*/logs" currentPage={props.fullPath} />
                  <NavbarAssetLink name="Alerts" targetPage="/asset/*/alerts" currentPage={props.fullPath} />
                </Fragment>
              }
            />

            <Route path="/scan-asset" element={<NavbarLink name="Create Asset" currentPage={props.fullPath} />} />

            {userHasPermission([[VIEW_SUBSCRIPTIONS_PERMISSION], [VIEW_DEVICE_STATUSES_PERMISSION]]) && (
              <Route
                path="/monitoring-devices/*"
                element={
                  <Fragment>
                    {userHasPermission([[VIEW_SUBSCRIPTIONS_PERMISSION]]) && (
                      <NavbarLink
                        name="Subscriptions"
                        targetPage="/monitoring-devices/subscriptions"
                        currentPage={props.fullPath}
                      />
                    )}

                    {userHasPermission([[VIEW_DEVICE_STATUSES_PERMISSION]]) && (
                      <NavbarLink
                        name="Statuses"
                        targetPage="/monitoring-devices/statuses"
                        currentPage={props.fullPath}
                      />
                    )}
                  </Fragment>
                }
              />
            )}

            <Route
              path="/data-processing/*"
              element={
                <Fragment>
                  {userHasPermission([[ALIAS_ATTRIBUTES_PERMISSION]]) && (
                    <NavbarLink
                      name="Attribute Aliases"
                      targetPage="/data-processing/attributes"
                      currentPage={props.fullPath}
                    />
                  )}

                  <NavbarLink
                    name="Attribute Information"
                    targetPage="/data-processing/attribute-info"
                    currentPage={props.fullPath}
                  />

                  {userHasPermission([[MANAGE_ALERT_THRESHOLDS_PERMISSION]]) && (
                    <NavbarLink
                      name="Alert Thresholds"
                      targetPage="/data-processing/alert-thresholds"
                      currentPage={props.fullPath}
                    />
                  )}
                </Fragment>
              }
            />

            <Fragment>
              <Route path="/review-account" element={<NavbarLink name="Join Company" currentPage={props.fullPath} />} />
              <Route path="/asset-review" element={<NavbarLink name="Create Asset" currentPage={props.fullPath} />} />
            </Fragment>
          </Fragment>
        )}

        <Route
          path="/alert"
          element={<NavbarLink name="Overview" targetPage="/alert/overview" currentPage={props.fullPath} />}
        />

        <Route
          path="/account"
          element={
            <Fragment>
              <NavbarLink name="Account Info" targetPage="/account/user-info" currentPage={props.fullPath} />
              <NavbarLink name="Change Password" targetPage="/account/password" currentPage={props.fullPath} />
            </Fragment>
          }
        />

        <Route path="/search" element={<NavbarLink name="Assets" currentPage={props.fullPath} />} />

        <Route path="*" element={null} />
      </Routes>
    </nav>
  );
}

NavbarChild.propTypes = {
  currentPage: PropTypes.string.isRequired,
  fullPath: PropTypes.string.isRequired,
};

interface Props {
  currentPage: string;
  fullPath: string;
}
