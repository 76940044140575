// --------------------------------------------------------------
// Created On: 2023-02-21
// Author: Zachary Thomas
//
// Last Modified: 2025-03-15
// Modified By: Zachary Thomas
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useEffect, Fragment } from "react";
import ColorPicker from "../../../../components/ColorPicker/ColorPicker";
import { VIEW_HIGHLIGHT_COLORS } from "../../../../constants/miscellaneous";
import PropTypes from "prop-types";
import { useDraggable } from "@dnd-kit/core";
import styles from "./HighlightingRuleForm.module.scss";

// Form for setting highlighting rules for attributes.
export default function HighlightingRuleForm(props: Props): Component {
  const [primaryValue, setPrimaryValue] = useState<string>("");
  const [secondaryValue, setSecondaryValue] = useState<string>("");
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.formId,
  });

  // When a new value is passed in, split it between the primary and secondary value if needed.
  useEffect(() => {
    const values = props.value.split(",");
    if (values.length === 1) {
      setPrimaryValue(values[0]);
      setSecondaryValue("");
    } else if (values.length > 1) {
      setPrimaryValue(values[0]);
      setSecondaryValue(values[1]);
    }
  }, [props.value]);

  // Send updated settings to the parent component.
  function updateSettings(
    comparator: ViewComparator,
    valueType: ViewValueType,
    primaryValue: string,
    secondaryValue: string,
    colorHexCode: string
  ): void {
    // If there is a valid secondary value, combine it into a single string with the primary value.
    let value = primaryValue;
    if (secondaryValue.length > 0) {
      value = `${primaryValue},${secondaryValue}`;
    }
    // Send changes to parent component.
    props.onChange(comparator, valueType, value, colorHexCode);
  }

  return (
    <div
      data-test="highlighting-rule-form-container"
      className="container m-0 py-0"
      ref={setNodeRef}
      style={
        transform
          ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            }
          : undefined
      }
    >
      <div className={`${styles.body} row m-0 py-0`}>
        <div className={`${styles.draggable} col-auto p-0 m-0`} {...attributes} {...listeners} />
        <div className="col pe-1">
          <div className={` row align-items-center mx-1`}>
            {(props.isBoolean || props.isEnumeration) && (
              <div className="col-auto py-2 mx-0">If a value is equal to </div>
            )}

            {props.isEnumeration && (
              <div className="col-auto py-2 mx-0">
                <select
                  data-test="view-attribute-comparator-selector"
                  className="form-select col-12"
                  value={primaryValue}
                  disabled={props.disabled}
                  onChange={(e) =>
                    updateSettings(
                      props.comparator,
                      props.valueType,
                      e.target.value,
                      secondaryValue,
                      props.colorHexCode
                    )
                  }
                >
                  {props.enumerationOptions.map((enumerationOption) => (
                    <option value={enumerationOption.index} key={enumerationOption.index}>
                      {enumerationOption.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {!props.isBoolean && !props.isEnumeration && (
              <Fragment>
                {props.valueType === "MAX" || props.valueType === "MIN" ? (
                  <div className="col-auto py-2 mx-0">
                    <label className={styles.title}>
                      {" "}
                      If {props.units.length === 0 ? "a value" : props.units.toLowerCase()} is equal to the{" "}
                    </label>
                  </div>
                ) : (
                  <Fragment>
                    <div className="col-auto py-2 mx-0">
                      <label className={styles.title}>
                        If {props.units.length === 0 ? "a value" : props.units.toLowerCase()} is{" "}
                      </label>
                    </div>

                    <div className="col-auto py-2 mx-0">
                      <select
                        className="form-select"
                        value={props.comparator}
                        disabled={props.disabled}
                        onChange={(e) =>
                          updateSettings(
                            e.target.value as ViewComparator,
                            props.valueType,
                            primaryValue,
                            secondaryValue,
                            props.colorHexCode
                          )
                        }
                      >
                        <option value=">">
                          {" "}
                          {props.valueType === "STANDARD_DEVIATION" ? "outside of" : "greater than"}{" "}
                        </option>
                        <option value="<">
                          {" "}
                          {props.valueType === "STANDARD_DEVIATION" ? "inside of" : "less than"}{" "}
                        </option>
                        {props.valueType === "NUMBER" && <option value="<>"> between </option>}
                        {props.valueType !== "STANDARD_DEVIATION" && <option value="="> equal to </option>}
                      </select>
                    </div>

                    <div className="col-auto py-2 mx-0">
                      <label className={styles.title}> the </label>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}

            {props.valueType === "STANDARD_DEVIATION" && (
              <div className="col-auto py-2 mx-0">
                <select
                  className="form-select"
                  value={primaryValue}
                  disabled={props.disabled}
                  onChange={(e) =>
                    updateSettings(
                      props.comparator,
                      props.valueType,
                      e.target.value,
                      secondaryValue,
                      props.colorHexCode
                    )
                  }
                >
                  <option value="1"> first </option>
                  <option value="2"> second </option>
                  <option value="3"> third </option>
                </select>
              </div>
            )}

            {!props.isBoolean && !props.isEnumeration && (
              <div className="col-auto py-2 mx-0">
                <select
                  className="form-select"
                  value={props.valueType}
                  disabled={props.disabled}
                  onChange={(e) =>
                    updateSettings(
                      props.comparator,
                      e.target.value as ViewValueType,
                      primaryValue,
                      secondaryValue,
                      props.colorHexCode
                    )
                  }
                >
                  <option value="NUMBER">number</option>
                  <option value="MEDIAN">median</option>
                  <option value="MEAN">mean</option>
                  <option value="STANDARD_DEVIATION">standard deviation</option>
                  <option value="MAX">maximum</option>
                  <option value="MIN">minimum</option>
                </select>
              </div>
            )}

            {!props.isEnumeration && props.valueType === "NUMBER" && (
              <Fragment>
                {props.isBoolean ? (
                  <div className="col-2 py-2 mx-0">
                    <select
                      className="form-select"
                      value={primaryValue}
                      disabled={props.disabled}
                      onChange={(e) =>
                        updateSettings(
                          props.comparator,
                          props.valueType,
                          e.target.value,
                          secondaryValue,
                          props.colorHexCode
                        )
                      }
                    >
                      <option value="0">False</option>
                      <option value="1">True</option>
                    </select>
                  </div>
                ) : (
                  <div className="col-3 col-lg-1 py-2 mx-0">
                    <input
                      className="form-control"
                      type="text"
                      value={primaryValue}
                      disabled={props.disabled}
                      onChange={(e) =>
                        updateSettings(
                          props.comparator,
                          props.valueType,
                          e.target.value,
                          secondaryValue,
                          props.colorHexCode
                        )
                      }
                    />
                  </div>
                )}
              </Fragment>
            )}

            {props.valueType === "NUMBER" && props.comparator === "<>" && (
              <Fragment>
                <div className="col-auto py-2 mx-0">
                  <label className={styles.title}> and </label>
                </div>
                <div className="col-3 col-lg-1 py-2 mx-0">
                  <input
                    className="form-control"
                    type="text"
                    value={secondaryValue}
                    disabled={props.disabled}
                    onChange={(e) =>
                      updateSettings(
                        props.comparator,
                        props.valueType,
                        primaryValue,
                        e.target.value,
                        props.colorHexCode
                      )
                    }
                  />
                </div>
              </Fragment>
            )}

            <div className="col-auto py-2 ps-1 mx-0">
              <label className={styles.title}> then highlight </label>
            </div>

            {/* Color selector */}
            <div className="col-auto py-2 px-0 mx-0">
              <ColorPicker
                colors={VIEW_HIGHLIGHT_COLORS}
                selectedColor={props.colorHexCode}
                disabled={props.disabled}
                onChange={(selectedColor) =>
                  updateSettings(props.comparator, props.valueType, primaryValue, secondaryValue, selectedColor)
                }
              />
            </div>

            <div className="col">
              <button
                type="button"
                className={`${styles.button} btn btn-danger float-end`}
                disabled={props.disabled}
                onClick={() => props.onDelete()}
              >
                <i className="fa fa-fw fa-times fa-xs" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

HighlightingRuleForm.propTypes = {
  formId: PropTypes.number.isRequired,
  comparator: PropTypes.string.isRequired,
  valueType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  colorHexCode: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  isBoolean: PropTypes.bool.isRequired,
  isEnumeration: PropTypes.bool.isRequired,
  enumerationOptions: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

interface Props {
  formId: number;
  comparator: ViewComparator;
  valueType: ViewValueType;
  value: string;
  colorHexCode: string;
  units: string;
  isBoolean: boolean;
  isEnumeration: boolean;
  enumerationOptions: EnumerationOption[];
  disabled?: boolean;
  onChange: (comparator: ViewComparator, valueType: ViewValueType, value: string, colorHexCode: string) => void;
  onDelete: () => void;
}

interface EnumerationOption {
  index: number;
  name: string;
}

type ViewValueType = "NUMBER" | "MEDIAN" | "MEAN" | "STANDARD_DEVIATION" | "MAX" | "MIN";

type ViewComparator = "=" | "!=" | "<" | ">" | "<>";
