// --------------------------------------------------------------
// Created On: 2025-02-05
// Author: Hannah Vaughan
//
// Last Modified: 2025-02-13
// Modified By: Dimitra Weinstein
//
// Copyright 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------
import { RPM_COMPANY } from "../constants/miscellaneous";
import store from "../redux/store";
import {
  CREATE_ADMIN_COMPANY_ROLE_PERMISSION,
  CREATE_ROLES_PERMISSION,
  DELETE_ADMIN_COMPANY_ROLE_PERMISSION,
  DELETE_ROLES_PERMISSION,
  UPDATE_ADMIN_COMPANY_ROLE_PERMISSION,
  UPDATE_ROLES_PERMISSION,
} from "../constants/permissions";
import userHasPermission from "./userHasPermission";

// Checks if the current user has permission to access the Manage Roles page.
export default function userHasRolePermissions(): boolean {
  let userHasPermissions = false;
  const storeState = store.getState();

  if (
    storeState !== undefined &&
    storeState.currentUser !== undefined &&
    storeState.currentUser.permissions !== undefined
  ) {
    if (storeState.currentUser.companyName === RPM_COMPANY) {
      if (
        userHasPermission([
          [CREATE_ADMIN_COMPANY_ROLE_PERMISSION],
          [UPDATE_ADMIN_COMPANY_ROLE_PERMISSION],
          [DELETE_ADMIN_COMPANY_ROLE_PERMISSION],
        ])
      ) {
        userHasPermissions = true;
      }
    } else {
      if (userHasPermission([[CREATE_ROLES_PERMISSION], [UPDATE_ROLES_PERMISSION], [DELETE_ROLES_PERMISSION]])) {
        userHasPermissions = true;
      }
    }
  }

  return userHasPermissions;
}
