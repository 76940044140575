// --------------------------------------------------------------
// Created On: 2021-08-26
// Author: Zachary Thomas
//
// Last Modified: 2025-02-18
// Modified By: Dimitra Weinstein
//
// Copyright 2024 - 2025 © Cornell Pump Company, All Rights Reserved
// --------------------------------------------------------------

import React, { useState, useReducer } from "react";
import useApi from "../../hooks/useApi";
import { API, CREATE_MODE, EDIT_MODE } from "../../constants/miscellaneous";
import { ASSET_TYPES, ASSETGROUP_TYPES } from "../../constants/reducerActions";
import Spinner from "../../components/Spinner/Spinner";
import ResourceList from "../../components/ResourceList/ResourceList";
import AssetModal from "../../components/AssetModal/AssetModal";
import AssetgroupModal from "./AssetgroupModal/AssetgroupModal";
import deepCopy from "../../utilities/deepCopy";
import Error500Page from "../Error500Page/Error500Page";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import userHasPermission from "../../utilities/userHasPermission";
import {
  CREATE_ASSETS_PERMISSION,
  UPDATE_ASSETS_PERMISSION,
  DELETE_ASSETS_PERMISSION,
  CREATE_ASSET_GROUPS_PERMISSION,
  UPDATE_ASSET_GROUPS_PERMISSION,
  DELETE_ASSET_GROUPS_PERMISSION,
} from "../../constants/permissions";
import HelpAssetgroup from "../../components/HelpAssetgroup/HelpAssetgroup";
import HelpAsset from "../../components/HelpAsset/HelpAsset";
import AssetgroupListRow from "./AssetgroupListRow/AssetgroupListRow";
import AssetListRow from "./AssetListRow/AssetListRow";
import styles from "./ManageAssetsPage.module.scss";

// Page for creating, editing, and deleting assets and asset groups.
export default function ManageAssetsPage(): Component {
  const [loading, setLoading] = useState<boolean>(true);
  const [failedToLoad, setFailedToLoad] = useState<boolean>(false);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [controllers, setControllers] = useState<Controller[]>([]);
  const [monitoringDevices, setMonitoringDevices] = useState<MonitoringDevice[]>([]);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedType, setSelectedType] = useState<string>("");
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [productManufacturers, setProductManufacturers] = useState<string[]>([]);
  const [analogSensors, setAnalogSensors] = useState<AnalogSensor[]>([]);
  const [digitalSensors, setDigitalSensors] = useState<DigitalSensor[]>([]);
  const [usergroups, setUsergroups] = useState<Usergroup[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [assetgroups, dispatchAssetgroup] = useReducer(assetgroupReducer, []);
  const [assets, dispatchAsset] = useReducer(assetReducer, []);
  const [assetMapIcons, setAssetMapIcons] = useState<AssetMapIcon[]>([]);
  const currentUser = useSelector(getCurrentUser);

  // Get asset data from API.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/company/${currentUser.companyId}/assetgroup/map`,
    },
    async (response: Response, responseBody: ResponseBody) => {
      if (response.ok && responseBody) {
        dispatchAssetgroup({
          type: ASSETGROUP_TYPES.SET_ASSETGROUPS,
          payload: {
            assetgroups: responseBody.assetgroups,
          },
        });
        dispatchAsset({
          type: ASSET_TYPES.SET_ASSETS,
          payload: {
            assets: responseBody.assets,
          },
        });
        setAssetMapIcons(responseBody.assetMapIcons);
        setDeviceTypes(responseBody.deviceTypes);
        setProductTypes(responseBody.productTypes);
        setAnalogSensors(responseBody.analogSensors);
        setDigitalSensors(responseBody.digitalSensors);
        setProductManufacturers(responseBody.productManufacturers);
        setControllers(responseBody.controllers);
        setMonitoringDevices(responseBody.monitoringDevices);
        setUsergroups(responseBody.usergroups);
        setTemplates(responseBody.templates);
        setFailedToLoad(false);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Change the currently selected target.
  function updateSelection(selectedId: number, selectedType: string): void {
    setSelectedId(selectedId);
    setSelectedType(selectedType);
  }

  // Asset reducer.
  function assetReducer(state: AssetListItem[], action: Action): AssetListItem[] {
    switch (action.type) {
      case ASSET_TYPES.SET_ASSETS: {
        const assets = action.payload.assets;
        if (assets !== undefined) {
          return assets;
        } else {
          return state;
        }
      }

      case ASSET_TYPES.CREATE_ASSET: {
        let stateDeepCopy = deepCopy(state);
        const newAsset = action.payload.asset;
        if (newAsset !== undefined) {
          // Check if the asset already exists before creating it.
          const assetExists = stateDeepCopy.some((asset) => asset.assetId === newAsset.assetId);
          // If this is a new asset, add it to the list in sorted order.
          if (!assetExists) {
            stateDeepCopy.push(newAsset);
            stateDeepCopy = sortAssets(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case ASSET_TYPES.UPDATE_ASSET: {
        let stateDeepCopy = deepCopy(state);
        const updatedAsset = action.payload.asset;
        if (updatedAsset !== undefined) {
          // Find the index of the asset.
          const assetIndex = stateDeepCopy.findIndex((asset) => asset.assetId === updatedAsset.assetId);
          // Don't continue if we couldn't find the asset.
          if (assetIndex === -1) {
            return state;
          }
          // Replace the current asset with the updated one and sort the list.
          stateDeepCopy.splice(assetIndex, 1, updatedAsset);
          stateDeepCopy = sortAssets(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case ASSET_TYPES.UPDATE_ASSET_NICKNAME: {
        const stateDeepCopy = deepCopy(state);
        const assetId = action.payload.assetId;
        const nickname = action.payload.nickname;
        if (assetId !== undefined && nickname !== undefined) {
          // Find the index of the asset.
          const assetIndex = stateDeepCopy.findIndex((asset) => asset.assetId === assetId);
          // Don't continue if we couldn't find the asset.
          if (assetIndex === -1) {
            return state;
          }
          // Update the asset's nickname.
          stateDeepCopy[assetIndex].nickname = nickname;
        }
        return stateDeepCopy;
      }

      case ASSET_TYPES.DELETE_ASSET: {
        const stateDeepCopy = deepCopy(state);
        const assetId = action.payload.assetId;
        if (assetId !== undefined) {
          // Find the index of the asset.
          const assetIndex = stateDeepCopy.findIndex((asset) => asset.assetId === assetId);
          // Don't continue if we couldn't find the asset.
          if (assetIndex === -1) {
            return state;
          }
          // Delete the current asset.
          stateDeepCopy.splice(assetIndex, 1);
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort assets.
  function sortAssets(assets: AssetListItem[]): AssetListItem[] {
    return assets.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Asset group reducer.
  function assetgroupReducer(state: Assetgroup[], action: Action): Assetgroup[] {
    switch (action.type) {
      case ASSETGROUP_TYPES.SET_ASSETGROUPS: {
        const assetgroups = action.payload.assetgroups;
        if (assetgroups !== undefined) {
          return sortAssetgroups(assetgroups);
        } else {
          return state;
        }
      }

      case ASSETGROUP_TYPES.CREATE_ASSETGROUP: {
        let stateDeepCopy = deepCopy(state);
        const newAssetgroup = action.payload.assetgroup;
        if (newAssetgroup !== undefined) {
          // If the asset group is set to auto-update, remove auto-update from other asset groups.
          if (newAssetgroup.isDefault) {
            stateDeepCopy.forEach((assetgroup) => (assetgroup.isDefault = false));
          }
          // Check if the asset group already exists before creating it.
          const assetgroupExists = stateDeepCopy.some(
            (assetgroup) => assetgroup.assetgroupId === newAssetgroup.assetgroupId
          );
          // If this is a new asset, add it to the list in sorted order.
          if (!assetgroupExists) {
            stateDeepCopy.push(newAssetgroup);
            stateDeepCopy = sortAssetgroups(stateDeepCopy);
          }
        }
        return stateDeepCopy;
      }

      case ASSETGROUP_TYPES.UPDATE_ASSETGROUP: {
        let stateDeepCopy = deepCopy(state);
        const updatedAssetgroup = action.payload.assetgroup;
        if (updatedAssetgroup !== undefined) {
          // If the asset group is set to auto-update, remove auto-update from other asset groups.
          if (updatedAssetgroup.isDefault) {
            stateDeepCopy.forEach((assetgroup) => (assetgroup.isDefault = false));
          }
          // Find the index of the asset group.
          const assetgroupIndex = stateDeepCopy.findIndex(
            (assetgroup) => assetgroup.assetgroupId === updatedAssetgroup.assetgroupId
          );
          // Don't continue if we couldn't find the asset group.
          if (assetgroupIndex === -1) {
            return state;
          }
          // Replace the current asset group with the updated one.
          stateDeepCopy.splice(assetgroupIndex, 1, updatedAssetgroup);
          stateDeepCopy = sortAssetgroups(stateDeepCopy);
        }
        return stateDeepCopy;
      }

      case ASSETGROUP_TYPES.DELETE_ASSETGROUP: {
        const stateDeepCopy = deepCopy(state);
        const assetgroupId = action.payload.assetgroupId;
        if (assetgroupId !== undefined) {
          // Find the index of the asset group.
          const assetgroupIndex = stateDeepCopy.findIndex((assetgroup) => assetgroup.assetgroupId === assetgroupId);
          // Don't continue if we couldn't find the asset group.
          if (assetgroupIndex === -1) {
            return state;
          }
          // Delete the current asset group.
          stateDeepCopy.splice(assetgroupIndex, 1);
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  // Sort asset groups.
  function sortAssetgroups(assetgroups: Assetgroup[]): Assetgroup[] {
    return assetgroups.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (a.isDefault) {
        return -1;
      } else if (b.isDefault) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Given an ID, gets an asset group's name.
  function getAssetgroupName(assetgroupId: number): string {
    let name = "";
    const assetgroup = assetgroups.find((assetgroup) => assetgroup.assetgroupId === assetgroupId);
    if (assetgroup !== undefined) {
      name = assetgroup.name;
    }
    return name;
  }

  // Given an ID, gets an asset group's 'is default' setting.
  function getAssetgroupIsDefault(assetgroupId: number): boolean {
    let isDefault = false;
    const assetgroup = assetgroups.find((assetgroup) => assetgroup.assetgroupId === assetgroupId);
    if (assetgroup !== undefined) {
      isDefault = assetgroup.isDefault;
    }
    return isDefault;
  }

  return failedToLoad ? (
    <Error500Page />
  ) : (
    <div className="page-manage-assets p-4">
      <Spinner loading={loading} />

      <div className="row">
        {/* Asset group management. */}

        {userHasPermission([
          [CREATE_ASSET_GROUPS_PERMISSION],
          [UPDATE_ASSET_GROUPS_PERMISSION],
          [DELETE_ASSET_GROUPS_PERMISSION],
        ]) && (
          <div className={`${styles.resourceList} col-12 col-md mx-0`} data-test="assetgroup-list">
            <ResourceList
              resourceNameSingular="Asset Group"
              resourceNamePlural="Asset Groups"
              resourceArticle="an"
              headerButtonLabel="Create Asset Group"
              headerButtonLabelSmall="Create"
              headerButtonUserPermissions={[[CREATE_ASSET_GROUPS_PERMISSION]]}
              resourceIdKey="assetgroupId"
              resourcePriorityKey="isDefault"
              resources={assetgroups}
              resourceRow={AssetgroupListRow}
              helpModal={<HelpAssetgroup />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => updateSelection(0, "assetgroup")}
              onSelect={(selectedId) => updateSelection(selectedId, "assetgroup")}
            />

            {/* Asset group modal for creating or editing asset groups. */}
            <AssetgroupModal
              mode={selectedId > 0 ? "edit" : "create"}
              showModal={selectedType === "assetgroup"}
              assetgroupId={selectedId}
              name={getAssetgroupName(selectedId)}
              isDefault={getAssetgroupIsDefault(selectedId)}
              assetgroups={assetgroups}
              assets={assets}
              usergroups={usergroups}
              onSelect={(selectedId, selectedType) => updateSelection(selectedId, selectedType)}
              onClose={() => updateSelection(-1, "")}
              onAction={(action) => dispatchAssetgroup(action as Action)}
            />
          </div>
        )}

        {/* Asset management. */}
        {userHasPermission([
          [CREATE_ASSETS_PERMISSION],
          [UPDATE_ASSETS_PERMISSION],
          [DELETE_ASSETS_PERMISSION],
          [CREATE_ASSET_GROUPS_PERMISSION],
          [UPDATE_ASSET_GROUPS_PERMISSION],
          [DELETE_ASSET_GROUPS_PERMISSION],
        ]) && (
          <div data-test="asset-list" className={`${styles.resourceList} col-12 col-md mx-0`}>
            <ResourceList
              resourceNameSingular="Asset"
              resourceNamePlural="Assets"
              resourceArticle="an"
              headerButtonLabel="Create Asset"
              headerButtonLabelSmall="Create"
              headerButtonUserPermissions={[[CREATE_ASSETS_PERMISSION]]}
              resourceIdKey="assetId"
              resources={assets}
              resourceRow={AssetListRow}
              helpModal={<HelpAsset />}
              filterKeys={["name"]}
              loading={loading}
              onClickHeaderButton={() => updateSelection(0, "asset")}
              onSelect={(selectedId) => updateSelection(selectedId, "asset")}
            />

            {/* Asset modal for creating or editing assets. */}
            <AssetModal
              assetId={selectedId}
              mode={selectedId > 0 ? EDIT_MODE : CREATE_MODE}
              showModal={selectedType === "asset"}
              assetMapIcons={assetMapIcons}
              deviceTypes={deviceTypes}
              controllers={controllers}
              productTypes={productTypes}
              productManufacturers={productManufacturers}
              monitoringDevices={monitoringDevices}
              analogSensors={analogSensors}
              digitalSensors={digitalSensors}
              templates={templates}
              onClose={() => updateSelection(-1, "")}
              onAction={(action: Action) => dispatchAsset(action)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

interface ResponseBody {
  assetgroups: Assetgroup[];
  assets: Asset[];
  assetMapIcons: AssetMapIcon[];
  controllers: Controller[];
  deviceTypes: DeviceType[];
  productManufacturers: string[];
  productTypes: ProductType[];
  monitoringDevices: MonitoringDevice[];
  usergroups: Usergroup[];
  analogSensors: AnalogSensor[];
  digitalSensors: DigitalSensor[];
  templates: Template[];
}

interface ProductType {
  productTypeId: number;
  name: string;
}

interface Assetgroup {
  assetgroupId: number;
  name: string;
  geofenceEnabled: boolean;
  geofenceType: string;
  geofencePoints: GeofencePoint[];
  assets: Asset[];
  assetgroups: Assetgroup[];
  usergroups: Usergroup[];
  isDefault: boolean;
}

interface GeofencePoint {
  geofencePointId: number;
  latitude: number;
  longitude: number;
  draggable?: boolean;
}

interface Asset {
  assetId: number;
  name: string;
  nickname: string;
  isRented?: boolean;
  isMigrating: boolean;
  lastConfigSuccessful?: boolean;
}

interface AssetMapIcon {
  assetMapIconId: number;
  name: string;
  code: string;
}

interface AssetListItem {
  assetId: number;
  name: string;
  nickname: string;
}

interface MonitoringDevice {
  formId?: number;
  deviceId: number;
  deviceType: string;
  deviceIdentifier: string;
  attributes?: DeviceAttribute[];
  deviceLog?: DeviceLog;
}

type DeviceLog = {
  lastUpdated: DeviceLogUnit;
} & {
  [key: string]: DeviceLogUnit;
};

interface DeviceLogUnit {
  code: string;
  name: string;
  value: string | number | boolean;
  latitude?: number;
  longitude?: number;
  unitLong: string;
  unitShort: string;
  unitSymbol: string;
  icon: string;
  isHistorical: boolean;
  sensorConnected?: boolean;
  mostRecentUtc?: string;
}

interface Usergroup {
  usergroupId: number;
  name: string;
  operateAccessPermission: boolean;
  users: User[];
  isDefault: boolean;
}

interface User {
  userId: number;
  name: string;
  emailAddress: string;
  roleId: number;
}

interface Controller {
  controllerId: number;
  controllerModel: string | null;
  manufacturerCompanyCode: string | null;
  name: string;
}

interface DeviceType {
  deviceTypeId: number;
  model: string;
  name: string;
}

interface Usergroup {
  usergroupId: number;
  name: string;
  operateAccessPermission: boolean;
  users: User[];
  isDefault: boolean;
}

interface AnalogSensor {
  analogSensorId: number;
  name: string;
  applications: AnalogApplication[];
}

interface AnalogApplication {
  attributeId: number;
  attributeConnectedId: number;
  name: string;
}

interface DigitalSensor {
  digitalSensorId: number;
  name: string;
  applications: DigitalApplication[];
}

interface DigitalApplication {
  attributeStateId: number;
  attributeFlowId: number;
  attributeAccumulationId: number;
  reportingDescription: string;
}

interface Template {
  assetTemplateId: number;
  name: string;
  isImmutable: boolean;
}

interface Action {
  type: string;
  payload: Payload;
}

interface Payload {
  assets?: AssetListItem[];
  asset?: AssetListItem;
  assetId?: number;
  nickname?: string;
  assetgroups?: Assetgroup[];
  assetgroup?: Assetgroup;
  assetgroupId?: number;
}
